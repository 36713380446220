export default function PvVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1351"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1351)">
				<path
					d="M2 22L4 12H20L22 22H2ZM3 4V2H6V4H3ZM4.45 20H11V18H4.85L4.45 20ZM6.125 9.325L4.7 7.925L6.825 5.8L8.25 7.2L6.125 9.325ZM5.25 16H11V14H5.65L5.25 16ZM12 7C10.6167 7 9.4375 6.5125 8.4625 5.5375C7.4875 4.5625 7 3.38333 7 2H9C9 2.83333 9.29167 3.54167 9.875 4.125C10.4583 4.70833 11.1667 5 12 5C12.8333 5 13.5417 4.70833 14.125 4.125C14.7083 3.54167 15 2.83333 15 2H17C17 3.38333 16.5125 4.5625 15.5375 5.5375C14.5625 6.5125 13.3833 7 12 7ZM11 11V8H13V11H11ZM13 20H19.55L19.15 18H13V20ZM13 16H18.75L18.35 14H13V16ZM17.875 9.325L15.775 7.2L17.175 5.8L19.3 7.9L17.875 9.325ZM18 4V2H21V4H18Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
