import { Header } from "semantic-ui-react";

import Page from "./Page";
import MapWithFilters from "../components/Map/MapWithFilters";

// Importing it here instead of in the component for MCM compatability 
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

export default function Mapping() {
	return (
		<Page>
			<Header as="h1">Mapping</Header>
			<MapWithFilters />
		</Page>
	);
}
