export default function FloorVentsVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1356"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1356)">
				<path
					d="M12 18C13.6667 18 15.0833 17.4167 16.25 16.25C17.4167 15.0833 18 13.6667 18 12C18 10.3333 17.4167 8.91667 16.25 7.75C15.0833 6.58333 13.6667 6 12 6C10.3333 6 8.91667 6.58333 7.75 7.75C6.58333 8.91667 6 10.3333 6 12C6 13.6667 6.58333 15.0833 7.75 16.25C8.91667 17.4167 10.3333 18 12 18ZM12 16C11.5167 16 11.05 15.9125 10.6 15.7375C10.15 15.5625 9.74167 15.3167 9.375 15H14.625C14.2583 15.3167 13.85 15.5625 13.4 15.7375C12.95 15.9125 12.4833 16 12 16ZM8.55 14C8.41667 13.7667 8.30833 13.525 8.225 13.275C8.14167 13.025 8.08333 12.7667 8.05 12.5H15.95C15.9167 12.7667 15.8583 13.025 15.775 13.275C15.6917 13.525 15.5833 13.7667 15.45 14H8.55ZM8.05 11.5C8.08333 11.2333 8.14167 10.975 8.225 10.725C8.30833 10.475 8.41667 10.2333 8.55 10H15.45C15.5833 10.2333 15.6917 10.475 15.775 10.725C15.8583 10.975 15.9167 11.2333 15.95 11.5H8.05ZM9.375 9C9.74167 8.68333 10.15 8.4375 10.6 8.2625C11.05 8.0875 11.5167 8 12 8C12.4833 8 12.95 8.0875 13.4 8.2625C13.85 8.4375 14.2583 8.68333 14.625 9H9.375ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
