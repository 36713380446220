import { Grid } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { useNavigate } from "../../shims/useNavigate";

import Button from "../UI/Button/Button";
import DetailsCard from "../../components/Property/DetailsCard";
import CustomImprovementsCard from "../../components/Property/CustomImprovementsCard";
import ImprovementsAvailableCard from "../../components/Property/ImprovementsAvailableCard";
import EnergyUsageCard from "../../components/Property/EnergyUsageCard";
import Co2EnergyOptimisationCard from "../../components/Property/Co2EnergyOptimisationCard";
import EpcSuggestionsModal from "../../components/Property/EpcSuggestionsModal";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import CustomUpdateButton from "../../components/CustomImprovements/CustomUpdateButton";

import useUpdateTrigger from "../../hooks/useUpdateTrigger";
import Property from "../../rest/Property";
import { PropertyResponse } from "../../rest/Property";
import { useRestClientContext } from "../../contexts/RestClientContext";
import Preloader from "../UI/Preloader/Preloader";
import DeletePropertyButton from "./DeletePropertyButton";
import PropertyImagesCard from "./PropertyImagesCard";

interface PropertyPanelProps
{
	teamId: number;
	propertyId: number;
}

export default function PropertyPanel({teamId, propertyId}: PropertyPanelProps)
{
	const client = useRestClientContext();
	const navigate = useNavigate();

	const [epcSuggestionsOpen, setEpcSuggestionsOpen] = useState(false);
	const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const { triggerUpdate } = useUpdateTrigger();
	const [property, setProperty] = useState<Property>();

	useEffect(() => {

		client
			.fetchProperty(teamId, propertyId)
			.then((response: Response) => response.json())
			.then((json: PropertyResponse) => {
				const property = new Property(json);
				setProperty(property);
			});

	}, [teamId, propertyId, client]);

	if(!property)
		return <Preloader />;

	const onOpenEpcSuggestions = () => {
		setEpcSuggestionsOpen(true);
	};

	const onDeleteProperty = () => {
		setConfirmDeleteModalOpen(true);
	};

	const onConfirmDeleteProperty = () => {
		setIsDeleting(true);

		client
			.deleteProperty(teamId, propertyId)
			.then(() => {
				navigate("/");
			});
	};

	return <>
		<DetailsCard property={property}>
			<Button variant="contained" onClick={onOpenEpcSuggestions}>
				Compare &amp; Update
			</Button>
		</DetailsCard>
		<CustomImprovementsCard property={property}>
			<CustomUpdateButton property={property} onUpdateProperty={() => { triggerUpdate(); return null; }}/>
		</CustomImprovementsCard>
		<Grid className="charts">
			<Grid.Row>
				<Grid.Column>
					<EnergyUsageCard property={property} />
				</Grid.Column>
				<Grid.Column>
					<Co2EnergyOptimisationCard property={property} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<ImprovementsAvailableCard property={property} />
		<PropertyImagesCard property={property} />
		<DeletePropertyButton onDelete={onDeleteProperty} />

		{epcSuggestionsOpen && (
			<EpcSuggestionsModal property={property} onClose={() => setEpcSuggestionsOpen(false)} />
		)}

		<ConfirmationModal 
			open={confirmDeleteModalOpen}
			title="Confirm delete"
			confirmationText="Confirm"
			buttonsDisabled={isDeleting}
			onConfirm={onConfirmDeleteProperty}
		 	onCancel={() => setConfirmDeleteModalOpen(false)}>
			<p>
				Are you sure you want to delete this property?
			</p>
			<p>
				<strong>This action cannot be undone.</strong>
			</p>
		</ConfirmationModal>
	</>;
}
