export default function DraughtWindVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1280"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1280)">
				<path
					d="M11.5 20C10.6667 20 9.95833 19.7083 9.375 19.125C8.79167 18.5417 8.5 17.8333 8.5 17H10.5C10.5 17.2833 10.5958 17.5208 10.7875 17.7125C10.9792 17.9042 11.2167 18 11.5 18C11.7833 18 12.0208 17.9042 12.2125 17.7125C12.4042 17.5208 12.5 17.2833 12.5 17C12.5 16.7167 12.4042 16.4792 12.2125 16.2875C12.0208 16.0958 11.7833 16 11.5 16H2V14H11.5C12.3333 14 13.0417 14.2917 13.625 14.875C14.2083 15.4583 14.5 16.1667 14.5 17C14.5 17.8333 14.2083 18.5417 13.625 19.125C13.0417 19.7083 12.3333 20 11.5 20ZM2 10V8H15.5C15.9333 8 16.2917 7.85833 16.575 7.575C16.8583 7.29167 17 6.93333 17 6.5C17 6.06667 16.8583 5.70833 16.575 5.425C16.2917 5.14167 15.9333 5 15.5 5C15.0667 5 14.7083 5.14167 14.425 5.425C14.1417 5.70833 14 6.06667 14 6.5H12C12 5.51667 12.3375 4.6875 13.0125 4.0125C13.6875 3.3375 14.5167 3 15.5 3C16.4833 3 17.3125 3.3375 17.9875 4.0125C18.6625 4.6875 19 5.51667 19 6.5C19 7.48333 18.6625 8.3125 17.9875 8.9875C17.3125 9.6625 16.4833 10 15.5 10H2ZM18.5 18V16C18.9333 16 19.2917 15.8583 19.575 15.575C19.8583 15.2917 20 14.9333 20 14.5C20 14.0667 19.8583 13.7083 19.575 13.425C19.2917 13.1417 18.9333 13 18.5 13H2V11H18.5C19.4833 11 20.3125 11.3375 20.9875 12.0125C21.6625 12.6875 22 13.5167 22 14.5C22 15.4833 21.6625 16.3125 20.9875 16.9875C20.3125 17.6625 19.4833 18 18.5 18Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
