import { useState, useEffect } from "react";
import { Table, Message, Icon } from "semantic-ui-react";
import CopyToClipboard from "react-copy-to-clipboard";

import { PropertyResponse } from "../../rest/Property";

import { useRestClientContext } from "../../contexts/RestClientContext";

import Modal from "../Modal/Modal";
import Suspense from "../UI/Suspense/Suspense";
import Uprn from "../UI/Uprn/Uprn";

import "./FailedJobsModal.scss";

interface Job {
	id: number;
	exception: string;
	property: PropertyResponse;
}

interface FailedJobsModalProps {
	teamId: number;
	batchId: string;
	onClose: Function;
}

export default function FailedJobsModal({teamId, batchId, onClose}: FailedJobsModalProps)
{
	const client = useRestClientContext();

	const [jobs, setJobs] = useState<Job[]>([]);
	const [promise, setPromise] = useState<Promise<any>>();

	useEffect(() => {

		setPromise(
			client
				.fetchBulkCustomImprovementBatch(teamId, batchId)
				.then((response: Response) => response.json())
				.then((json: Job[]) => setJobs(json))
		);

	}, [teamId, batchId, client]);

	return <Modal 
		title="Failed jobs"
		onClose={() => onClose()}
	>
		<Suspense promise={promise}>
			<Table className="failed-jobs">
				<Table.Header>
					<Table.Row>
						<Table.Cell>
							Property
						</Table.Cell>
						<Table.Cell>
							Message
						</Table.Cell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{
						jobs.map(job => <Table.Row key={job.id}>
							<Table.Cell>
								<Uprn property={job.property}>{job.property.uprn}</Uprn>
							</Table.Cell>
							<Table.Cell className="exception">
								<div>
									<Message error compact>
										{job.exception}
									</Message>
									<CopyToClipboard text={job.exception}>
										<Icon name="copy" title="Copy to clipboard"/>
									</CopyToClipboard>
								</div>
							</Table.Cell>
						</Table.Row>)
					}
				</Table.Body>
			</Table>
		</Suspense>
	</Modal>;
}