import { Button as SemanticUiButton, ButtonProps as SemanticUiButtonProps } from "semantic-ui-react";
import "./Button.scss";

export interface ButtonProps extends Omit<SemanticUiButtonProps, "onClick"> {
	variant?: string;
	right?: boolean;
	inverse?: boolean;
	disabled?: boolean;
	onClick?: Function;
}

export default function Button({ variant, right, inverse, disabled, children, onClick, ...forward }: ButtonProps) {
	const attributes = {
		disabled,
		className: variant ?? "underline",
		onClick: () => {} // TODO: Not great practise, nullable function please..
	};

	if(right)
		attributes.className += " right";
	
	if(inverse)
		attributes.className += " inverse";

	if (forward.className)
		attributes.className += " " + forward.className;

	// TODO: Why is this needed?
	if (onClick) attributes.onClick = () => onClick();

	return <SemanticUiButton {...forward} {...attributes}>{children}</SemanticUiButton>;
}
