import { Grid } from "semantic-ui-react";
import Button from "../UI/Button/Button";
import Modal from "./Modal";
import { PropsWithChildren } from "react";

interface ConfirmationModalProps extends PropsWithChildren {
	confirmationText?: string;
	cancellationText?: string;
	title: string;
	open: boolean;
	buttonsDisabled: boolean;
	onConfirm: Function;
	onCancel: Function;
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
	let confirmationText = props.confirmationText ?? "OK";
	let cancellationText = props.cancellationText ?? "Cancel";

	return (
		<Modal title={props.title} open={props.open} onClose={() => props.onCancel()}>
			{props.children}
			<Grid>
				<Grid.Row style={{ justifyContent: "flex-end" }}>
					<Button variant="contained" disabled={props.buttonsDisabled} onClick={() => props.onConfirm()}>
						{confirmationText}
					</Button>
					<Button variant="outlined" disabled={props.buttonsDisabled} onClick={() => props.onCancel()}>
						{cancellationText}
					</Button>
				</Grid.Row>
			</Grid>
		</Modal>
	);
}
