import Page from "./Page";

import ImportMonitorComponent from "../components/Import/ImportMonitorComponent";

export default function ImportMonitor() {
	return (
		<Page>
			<ImportMonitorComponent/>
		</Page>
	);
}
