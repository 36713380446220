import { Grid } from "semantic-ui-react";

import SummaryReport from "./SummaryReport/SummaryReport";
import NetZeroJourney from "./NetZeroJourney/NetZeroJourney";
import NetZeroGoal from "./NetZeroGoal/NetZeroGoal";
import FavouriteGroups from "./FavoriteGroups/FavouriteGroups";
import EpcData from "./EpcData/EpcData";
import OrderThermalSurveys from "./OrderThermalSurveys/OrderThermalSurveys";
import YourProperties from "./YourProperties/YourProperties";
import PerformanceStats from "./PerformanceStats/PerformanceStats";
import RequestNewWidget from "./RequestNewWidget/RequestNewWidget";

export default function DashboardGrid() {
	return (
        <Grid stackable columns={2}>
            <Grid.Column width={12}>
                <EpcData />
            </Grid.Column>
            <Grid.Column width={4}>
                <SummaryReport />
            </Grid.Column>

            <Grid.Column width={7}>
                <FavouriteGroups />
            </Grid.Column>
            <Grid.Column width={9}>
                <NetZeroGoal />
            </Grid.Column>


            <Grid.Column width={12}>
                <NetZeroJourney />
            </Grid.Column>
            <Grid.Column width={4}>
                <OrderThermalSurveys />
            </Grid.Column>

            <Grid.Column width={16}>
                <YourProperties />
            </Grid.Column>

            <Grid.Column width={12}>
                <PerformanceStats />
            </Grid.Column>
            <Grid.Column width={4}>
                <RequestNewWidget />
            </Grid.Column>
        </Grid>
	);
}
