/* mears-mcmview-override-contents

// NB: We re-export this for TypeScript so that it doesn't complain about UseRestClientContextService not being a Client. We don't need Client at all, but we do need type checks to pass in MCMView.

import { UseRestClientContextService } from '../services/use-rest-client-context.service';

export default UseRestClientContextService;

export interface PropertyQuery
{
	epc?: string;
	filter?: string;
	integrity_rating?: string;
	page_number?: number;
	page_size?: number;
	polygonPath?: string;
	searchTerms?: string;
	sort?: "asc" | "desc";
	uprn?: string;
	orderBy?: string;
	filters?: string;
	fields?: string[];
	signal?: AbortSignal;
}

*/

import env from "react-dotenv";
import download from "downloadjs";
import queryString from "query-string";

import Property from "./Property";
import { SmartTarget } from "../types/types";
import Team from "./Team";
import IClient, { AllSmartTargetParameters, IPropertyDownloadQuery } from "./IClient";

// NB: This is shared with HEERO. Maybe we should make this into a package

function isObject(item: any) {
	return item && typeof item === "object" && !Array.isArray(item);
}

// TODO: Probably best to find a type safe library that will do this
// @ts-ignore
function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) {
					Object.assign(target, { [key]: {} });
				} else {
					target[key] = Object.assign({}, target[key]);
				}
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}

// type PropertyQueryOrderBy = Array< Record<string, string | number | boolean | undefined> >;
// type PropertyQueryFilters = PropertyQueryOrderBy;

// TODO: This is repeated above for MCM. Not ideal. Consider moving it into a different file please.
export interface PropertyQuery
{
	epc?: string;
	filter?: string;
	integrity_rating?: string;
	page_number?: number;
	page_size?: number;
	polygonPath?: string;
	searchTerms?: string;
	sort?: "asc" | "desc";
	uprn?: string;
	orderBy?: string;
	filters?: string;
	fields?: string[];
	signal?: AbortSignal;
}

export default class Client implements IClient
{
	private jwtIdToken: string;

	constructor(jwtIdToken: string) {
		if (!/^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)?\.([a-zA-Z0-9_\-+/=]*)/.test(jwtIdToken))
			throw new Error("Invalid JWT");

		this.jwtIdToken = jwtIdToken;
	}

	private addRequestParams(json: any, params: any) {
		const defaults = {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		};

		if (!params) params = {};

		mergeDeep(params, defaults);

		params.body = JSON.stringify(json);

		return params;
	}

	private request(method: string, resource: string, params: any): Promise<Response> {
		const options = {
			headers: {
				Authorization: `Bearer ${this.jwtIdToken}`
			},
			method: method,
			signal: null,
			body: null
		};

		if (params) mergeDeep(options, params);

		// NB: Workaround to preserve type for AbortSignals. Look at a better strategy such as https://github.com/patrickkunka/helpful-merge
		if (params?.signal) options.signal = params.signal;

		// NB: Workaround to preserve type for FormData. Look at a bettery strategy such as https://github.com/patrickkunka/helpful-merge
		if (params?.body instanceof FormData) options.body = params.body;

		return fetch(`${env.API_V2_BASE_URL}${resource}`, options)
			.then((response) => {
				if (!response.ok)
					return response.text().then((message) => {
						throw new Error(response.statusText + " - " + message); // NB: Throw 404's etc as errors
					});

				return response;
			});
	}

	private get(resource: string, params?: any): Promise<any> {
		return this.request("GET", resource, params);
	}

	private post(resource: string, json: any, params?: any): Promise<any> {
		return this.request("POST", resource, this.addRequestParams(json, params));
	}

	private put(resource: string, json?: any, params?: any): Promise<any> {
		return this.request("PUT", resource, this.addRequestParams(json, params));
	}

	private destroy(resource: string, json?: any, params?: never): Promise<any> {
		return this.request("DELETE", resource, this.addRequestParams(json, params));
	}

	private download(resource: string, mimeType: string): Promise<void> {
		let filename = "response";

		return this.get(resource)
			.then((response) => {
				const disposition = response.headers.get("Content-Disposition");
				filename = disposition!.split(/;(.+)/)[1].split(/=(.+)/)[1];
				if (filename.toLowerCase().startsWith("utf-8''"))
					filename = decodeURIComponent(filename.replace("utf-8''", ""));
				else filename = filename.replace(/['"]/g, "");

				return response.text();
			})
			.then((data) => {
				download(data, filename, mimeType);
			});
	}

	private upload(resource: string, formData: FormData) {
		if (!(formData instanceof FormData)) throw new Error("Argument must be an instance of FormData");

		return this.request("POST", resource, {
			body: formData,
		});
	}

	fetchPropertyFromUprn(uprn: string, teamId: string | number) {
		return this.get(`/api/teams/${teamId}/properties?uprn=${uprn}`);
	}

	fetchTeamProperties(team: Team | number, query?: PropertyQuery, signal?: AbortSignal) {

		const defaults: PropertyQuery = {
			epc: "",
			filter: "none",
			integrity_rating: "",
			polygonPath: "{}",
			searchTerms: "",
			sort: "asc",
			uprn: "",
		};

		const params: PropertyQuery = Object.assign(defaults, query ?? {}) as PropertyQuery;
		const qstr = queryString.stringify(
			params,
			{
				arrayFormat: "bracket"
			}
		);
		const options: {signal?: AbortSignal} = {};

		if(signal)
			options.signal = signal;

		const id: number = team instanceof Team ? team.id : team;

		return this.get(`/api/teams/${id}/properties?${qstr}`, options);

	}

	fetchCustomImprovements(): Promise<Response>
	{
		return this.get("/api/custom-improvements");
	}

	fetchCustomImprovementPresets(): Promise<Response>
	{
		return this.get("/api/custom-improvements/presets");
	}

	fetchEpcDataOverview(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/widgets/epc-data-overview`);
	}

	fetchGroups(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/groups`);
	}

	fetchNetZeroGoal(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/widgets/net-zero-goal`);
	}

	fetchNetZeroJourney(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/widgets/net-zero-journey`);
	}

	fetchStats(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/widgets/stats`);
	}

	fetchLeaderboards(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/widgets/leaderboards`);
	}

	uploadImport(teamId: number, formData: FormData): Promise<Response>
	{
		return this.upload(`/api/teams/${teamId}/properties/import`, formData);
	}

	uploadPropertyImages(property: Property, formData: FormData): Promise<Response>
	{
		return this.upload(`/api/teams/${property.teamId}/properties/${property.id}/images`, formData);
	}

	updateTeamProperties(teamId: number, data: any): Promise<Response>
	{
		return this.put(`/api/teams/${teamId}/properties`, data);
	}

	createTeam(name: string): Promise<Response>
	{
		return this.post(`/api/teams`, {
			name
		});
	}

	fetchGroupProperties(teamId: number, groupId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/groups/${groupId}/properties`);
	}

	updatePropertySapData(teamId: number, propertyId: number): Promise<Response>
	{
		return this.put(`/api/teams/${teamId}/properties/${propertyId}/update_sap`);
	}

	fetchBulkCustomImprovementBatches(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/bulk-custom-improvement-batches`);
	}

	fetchBulkCustomImprovementBatchAggregate(teamId: number, batchIds: string[]): Promise<Response>
	{
		const queryParam = queryString.stringify({ batch_ids: batchIds }, { arrayFormat: "bracket" });
		return this.get(`/api/teams/${teamId}/bulk-custom-improvement-batches/aggregate?${queryParam}`);
	}

	fetchBulkCustomImprovementBatch(teamId: number, batchUuid: string): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/bulk-custom-improvement-batches/${batchUuid}`);
	}

	deleteBulkCustomImprovementBatch(teamId: number, batchUuid: string): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/bulk-custom-improvement-batches/${batchUuid}`);
	}

	deleteCustomImprovementSet(teamId: number, propertyId: number, setId: number): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/properties/${propertyId}/custom-improvement-sets/${setId}`);
	}

	updateProperty(teamId: number, propertyId: number, data: any): Promise<Response>
	{
		return this.put(`/api/teams/${teamId}/properties/${propertyId}`, data);
	}

	updateUser(userId: number, data: any): Promise<Response>
	{
		return this.put(`/api/users/${userId}`, data);
	}

	deleteTeamProperties(teamId: number, propertyIds: number[]): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/properties`, propertyIds);
	}

	deleteGroup(teamId: number, groupId: number): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/groups/${groupId}`);
	}

	updateGroup(teamId: number, groupId: number, data: any): Promise<Response>
	{
		return this.put(`/api/teams/${teamId}/groups/${groupId}`, data);
	}

	fetchTeams(): Promise<Response>
	{
		return this.get("/api/teams");
	}

	fetchTeam(id: number): Promise<Response>
	{
		return this.get(`/api/teams/${id}`);
	}

	updateTeamUsers(teamId: number, data: any): Promise<Response>
	{
		return this.post(`/api/teams/${teamId}/users`, data);
	}

	deleteTeamUser(teamId: number, userId: number): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/users/${userId}`);
	}

	updateTeamUser(teamId: number, userId: number, data: any): Promise<Response>
	{
		return this.put(`/api/teams/${teamId}/users/${userId}`);
	}

	fetchEditableFieldValues(): Promise<Response>
	{
		return this.get("/api/properties/editable-field-values");
	}

	fetchEpcRegisterSearch(postcode: string): Promise<Response>
	{
		const encoded = encodeURIComponent(postcode);

		return this.get(`/api/epc-register/search?postcode=${encoded}&limit=5000`);
	}

	convertPublicEpcData(data: any): Promise<Response>
	{
		return this.post("/api/odc-epc/domestic/property/convert", data);
	}

	fetchTeamPropertyAggregates(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/properties/aggregates`);
	}

	createGroupFromUprnSpreadsheetUpload(teamId: number, formData: FormData)
	{
		return this.upload(`/api/teams/${teamId}/groups`, formData);
	}

	fetchTeamGroups(teamId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/groups`);
	}

	fetchImport(id: number): Promise<Response>
	{
		return this.get(`/api/imports/${id}`);
	}

	deleteProperty(teamId: number, propertyId: number): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/properties/${propertyId}`);
	}

	fetchProperty(teamId: number, propertyId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/properties/${propertyId}`);
	}

	createGroup(teamId: number, data: any): Promise<Response>
	{
		return this.post(`/api/teams/${teamId}/groups`, data);
	}

	updateGroupProperties(teamId: number, groupId: number, data: any): Promise<Response>
	{
		return this.put(`/api/teams/${teamId}/groups/${groupId}/properties`, data);
	}

	deleteGroupProperties(teamId: number, groupId: number, data: any): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/groups/${groupId}/properties`, data);
	}

	fetchMe(): Promise<Response>
	{
		return this.get("/api/users/me");
	}

	fetchSuggestedCustomImprovements(teamId: number, propertyId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/properties/${propertyId}/custom-improvements/suggested`);
	}

	createPropertyCustomImprovements(property: Property, data: any): Promise<Response>
	{
		return this.post(`/api/teams/${property.teamId}/properties/${property.id}/custom-improvement-sets`, data);
	}

	createMultiPropertyCustomImprovements(teamId: number, data: any): Promise<Response>
	{
		return this.post(`/api/teams/${teamId}/properties/custom-improvement-sets`, data);
	}

	fetchAllPropertySmartTargets(
		teamId: number,
		propertyId: number,
		include?: AllSmartTargetParameters[],
	): Promise<Response> {
		const params = new URLSearchParams();
		if (include?.length) {
			include.forEach((param) => params.set(param, ''));
		}
		return this.get(`/api/teams/${teamId}/properties/${propertyId}/smart-targets?${params.toString()}`);
	}

	fetchPropertySmartTargets(teamId: number, propertyId:number, smartTargetId: number): Promise<Response>
	{
		return this.get(`/api/teams/${teamId}/properties/${propertyId}/smart-targets/${smartTargetId}`);
	}

	createSmartTargets(teamId: number, property: Property, data: any): Promise<Response>
	{
		return this.post(`/api/teams/${teamId}/properties/${property.id}/smart-targets`, data);
	}

	deleteSmartTargets(teamId: number, property: Property, smartTarget: SmartTarget): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/properties/${property.id}/smart-targets/${smartTarget.id}`);
	}

	cancelPendingSmartTargets(teamId: number, property: Property, smartTarget: SmartTarget): Promise<Response>
	{
		return this.destroy(`/api/teams/${teamId}/properties/${property.id}/smart-targets/${smartTarget.id}/cancel`);
	}

	deletePropertyImages(property: Property): Promise<Response>
	{
		return this.destroy(`/api/teams/${property.teamId}/properties/${property.id}/images`);
	}

	downloadTeamProperties(teamId: number, query?: IPropertyDownloadQuery): Promise<void>
	{
		return this.download(`/api/teams/${teamId}/properties/export?` + queryString.stringify(query ?? {}), "text/csv");
	}

	downloadGroupProperties(teamId: number, groupId: number, query?: IPropertyDownloadQuery): Promise<void>
	{
		return this.download(`/api/teams/${teamId}/groups/${groupId}/properties/export?` + queryString.stringify(query ?? {}), "text/csv");
	}
}
