import { Header } from "semantic-ui-react";

import Page from "./Page";

import GroupUpload from "../components/Groups/GroupUpload";
import GroupCards from "../components/Groups/GroupCards";

export default function Groups() {
	return (
		<Page>
			<Header as="h1">Your Groups</Header>

			<GroupUpload/>
			<GroupCards/>
		</Page>
	);
}
