import React, { useEffect, useState } from "react";
import Card from "../../UI/Card/Card";
import PerformanceStatChart from "./PerformanceStatChart";
import "./PerformanceStats.scss";
import EpcRatingTile from "../../UI/EpcRatingTile/EpcRatingTile";
import Preloader from "../../UI/Preloader/Preloader";

import { useTeamContext } from "../../../contexts/TeamContext";
import { useRestClientContext } from "../../../contexts/RestClientContext";
import { BarDatum } from "@nivo/bar";
import { Message } from "semantic-ui-react";

interface PerformanceStatsResponse
{
	total_co2: number;
	potential_co2: number;
	total_energy_cost: number;
	potential_energy_cost: number;
	total_energy_use: number;
	potential_energy_use: number;
	average_co2: number;
	potential_average_co2: number;
	average_energy_cost: number;
	potential_average_energy_cost: number;
	potential_total_energy_use: number;
	average_energy_use: number;
	potential_average_energy_use: number;
}

interface PerformanceStatsDatum extends BarDatum
{
	label: string;
	Current: number;
	Potential: number;
}

const PerformanceStats = () => {
	const team = useTeamContext();
	const client = useRestClientContext();
	const [data, setData] = useState<Array<PerformanceStatsDatum>>();
	const [error, setError] = useState<string>("");

	useEffect(() => {
		setError("");

		client
			.fetchStats(team.id)
			.then((response: Response) => response.json())
			.then((json: PerformanceStatsResponse) => {
				setData([
					{
						label: "Total CO2 (tonnes)",
						Current: json.total_co2 / 1000,
						Potential: json.potential_co2 / 1000,
					},
					{
						label: "Total Energy Cost (£)",
						Current: json.total_energy_cost,
						Potential: json.potential_energy_cost,
					},
					{
						label: "Total Energy Use (MW/h)",
						Current: json.total_energy_use / 1000,
						Potential: json.potential_energy_use / 1000,
					},
					{
						label: "Average CO2 (tonnes)",
						Current: json.average_co2 / 1000,
						Potential: json.potential_average_co2 / 1000,
					},
					{
						label: "Average Energy Cost (£)",
						Current: json.average_energy_cost,
						Potential: json.potential_average_energy_cost,
					},
					{
						label: "Average Energy Use (MW/h)",
						Current: json.average_energy_use / 1000,
						Potential: json.potential_average_energy_use / 1000,
					},
				]);
			})
			.catch((_) => setError("Error fetching performance stats"));
	}, [team, client]);

	return (
		<Card title="Performance Stats">
			{data ? (
				<>
					<div className="performance-stats-container-checkbox">
						<EpcRatingTile grade="F" compact />
						<span>Current</span>
						<EpcRatingTile grade="C" compact />
						<span>2025 Budget</span>
					</div>
					<div>
						<div className="performance-stats-container">
							{data.map((obj, index) => {
								return (
									<div key={index}>
										<PerformanceStatChart data={obj} />
									</div>
								);
							})}
						</div>
						<div className="performance-stats-label">
							{data.map((obj, index) => {
								return <div key={index}>{obj.label}</div>;
							})}
						</div>
					</div>
				</>
			) : error ? (
				<Message error>{error}</Message>
			) : (
				<Preloader />
			)}
		</Card>
	);
};

export default PerformanceStats;
