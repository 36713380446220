export default function DoorVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1315"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1315)">
				<path
					d="M3 21V19H5V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V19H21V21H3ZM17 19V5H7V19H17ZM14 13C14.2833 13 14.5208 12.9042 14.7125 12.7125C14.9042 12.5208 15 12.2833 15 12C15 11.7167 14.9042 11.4792 14.7125 11.2875C14.5208 11.0958 14.2833 11 14 11C13.7167 11 13.4792 11.0958 13.2875 11.2875C13.0958 11.4792 13 11.7167 13 12C13 12.2833 13.0958 12.5208 13.2875 12.7125C13.4792 12.9042 13.7167 13 14 13Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
