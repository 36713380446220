import React, { useState, useEffect, useCallback } from "react";
import { DotButton, PrevButton, NextButton } from "./EmblaCarouselButtons";
import useEmblaCarousel from "embla-carousel-react";
import { Grid, Header } from "semantic-ui-react";
import Case from "case";

import "./embla.css";

interface Slide {
	url: string;
}

interface EmblaCarouselProps {
	slides: Slide[];
}

const EmblaCarousel = ({ slides }: EmblaCarouselProps) => {
	const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
	const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
	const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla, setSelectedIndex]);

	useEffect(() => {
		if (!embla) return;
		onSelect();
		setScrollSnaps(embla.scrollSnapList());
		embla.on("select", onSelect);
	}, [embla, setScrollSnaps, onSelect]);

	return (
		<>
			<div className="embla">
				<div className="embla__viewport" ref={viewportRef}>
					<div className="embla__container">
						{slides.map((obj: any, index: number) => (
							<div className="embla__slide" key={index}>
								<div className="embla__slide__inner">
									<div className="thermal-image-container">
										<img className="embla__slide__img" src={obj.url} alt="Property" />
									</div>
									<div className="thermal-image-details-container">
										<Grid>
											{Object.keys(obj)
												.filter((key) => key !== "url")
												.map((key) => {
													return (
														<Grid.Row key={key}>
															<Grid.Column width={8}>
																<Header>{Case.title(key.replace("_", " "))}</Header>
															</Grid.Column>
															<Grid.Column width={8}>{obj[key]}</Grid.Column>
														</Grid.Row>
													);
												})}
										</Grid>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
				<NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
			</div>
			<div className="embla__dots">
				{scrollSnaps.map((_, index) => (
					<DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
				))}
			</div>
		</>
	);
};

export default EmblaCarousel;
