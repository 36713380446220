import React, { ReactNode, useState } from "react";
import { X } from "react-feather";
import { Label, LabelProps } from "semantic-ui-react";

import "./ChipLabel.scss";

export interface ChipLabelProps extends LabelProps {
	label: string;
	rounded?: "rounded" | "square";
	closable?: boolean;
	onClose?: () => void;
	variant?: "positive" | "negative" | "neutral";
	icon?: ReactNode;
}

export default function ChipLabel({ label, rounded, closable, onClose, variant, icon, ...props }: ChipLabelProps) {
	const [isClosed, setClosed] = useState<boolean>(false);
	const onChipClose = () => {
		setClosed(true);
		onClose && onClose();
	};

	return isClosed ? (
		<></>
	) : (
		<Label className={`chip-${variant} ${rounded}`} {...props}>
			<div className="chip-container" style={{ display: "flex" }}>
				{icon}
				<span>{label}</span>
				{closable && <X className="chip-close" onClick={onChipClose} color="black" size="1rem" />}
			</div>
		</Label>
	);
}
