export default function BlockChimneyVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1263"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1263)">
				<rect x="6" y="8" width="12" height="4" stroke="#1C1B1F" strokeWidth="2" />
				<path d="M18 6V4H6V6" stroke="#1C1B1F" strokeWidth="2" />
				<path d="M17 21V12H7V21" stroke="#1C1B1F" strokeWidth="2" />
			</g>
		</svg>
	);
}
