export default function SmartHeatingControlsVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1361"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1361)">
				<path
					d="M8 21C6.61667 21 5.4375 20.5125 4.4625 19.5375C3.4875 18.5625 3 17.3833 3 16V8C3 6.61667 3.4875 5.4375 4.4625 4.4625C5.4375 3.4875 6.61667 3 8 3H16C17.3833 3 18.5625 3.4875 19.5375 4.4625C20.5125 5.4375 21 6.61667 21 8V16C21 17.3833 20.5125 18.5625 19.5375 19.5375C18.5625 20.5125 17.3833 21 16 21H8ZM8 19H16C16.8333 19 17.5417 18.7083 18.125 18.125C18.7083 17.5417 19 16.8333 19 16V8C19 7.16667 18.7083 6.45833 18.125 5.875C17.5417 5.29167 16.8333 5 16 5H8C7.16667 5 6.45833 5.29167 5.875 5.875C5.29167 6.45833 5 7.16667 5 8V16C5 16.8333 5.29167 17.5417 5.875 18.125C6.45833 18.7083 7.16667 19 8 19ZM12 17C13.25 17 14.3125 16.5625 15.1875 15.6875C16.0625 14.8125 16.5 13.75 16.5 12.5C16.5 11.25 16.0625 10.1875 15.1875 9.3125C14.3125 8.4375 13.25 8 12 8C10.75 8 9.6875 8.4375 8.8125 9.3125C7.9375 10.1875 7.5 11.25 7.5 12.5C7.5 13.75 7.9375 14.8125 8.8125 15.6875C9.6875 16.5625 10.75 17 12 17ZM12 15C11.3 15 10.7083 14.7583 10.225 14.275C9.74167 13.7917 9.5 13.2 9.5 12.5C9.5 11.8 9.74167 11.2083 10.225 10.725C10.7083 10.2417 11.3 10 12 10C12.7 10 13.2917 10.2417 13.775 10.725C14.2583 11.2083 14.5 11.8 14.5 12.5C14.5 13.2 14.2583 13.7917 13.775 14.275C13.2917 14.7583 12.7 15 12 15ZM12 7.5C12.2833 7.5 12.5208 7.40417 12.7125 7.2125C12.9042 7.02083 13 6.78333 13 6.5C13 6.21667 12.9042 5.97917 12.7125 5.7875C12.5208 5.59583 12.2833 5.5 12 5.5C11.7167 5.5 11.4792 5.59583 11.2875 5.7875C11.0958 5.97917 11 6.21667 11 6.5C11 6.78333 11.0958 7.02083 11.2875 7.2125C11.4792 7.40417 11.7167 7.5 12 7.5Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
