import React, { useEffect, useState } from "react";
import Card from "../../UI/Card/Card";

import ChartLegend from "./ChartLegend";
import BudgetRequireChart from "./BudgetRequiredChart";
import CO2Chart from "./CO2Chart";
import Preloader from "../../UI/Preloader/Preloader";

import InitiativeButtons from "./InitiativeButtons";

import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useTeamContext } from "../../../contexts/TeamContext";

import "./NetZeroJourney.scss";
import { Serie } from "@nivo/line";
import { Message } from "semantic-ui-react";

interface NetZeroJourneyResponse
{
	epc_rating_total: number;
	epc_rating_total_2050_target: number;
	projected_spend: number;
	co2_target_start: number;
}

const NetZeroJourney = () => {
	const commonChartProps = {
		margin: { top: 50, right: 70, bottom: 50, left: 70 },
	};

	const team = useTeamContext();
	const client = useRestClientContext();
	const [loading, setLoading] = useState(true);
	const [co2Data, setCo2Data] = useState<Serie[]>([]);
	const [budgetRequiredData, setBudgetRequiredData] = useState<Serie[]>([]);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		setLoading(true);
		setError("");

		client
			.fetchNetZeroJourney(team.id)
			.then((response: Response) => response.json())
			.then((json: NetZeroJourneyResponse) => {
				setLoading(false);

				const start = json.co2_target_start;

				setCo2Data([
					{
						id: "CO2 Target",
						data: [
							{
								x: 2023,
								y: start,
							},
							{
								x: 2030,
								y: start * 0.666 + json.epc_rating_total_2050_target * 0.333,
							},
							{
								x: 2040,
								y: start * 0.333 + json.epc_rating_total_2050_target * 0.666,
							},
							{
								x: 2050,
								y: json.epc_rating_total_2050_target,
							},
						],
					},
				]);

				setBudgetRequiredData([
					{
						id: "Budget Required",
						data: [
							{
								x: 2023,
								y: 0,
							},
							{
								x: 2050,
								y: json.projected_spend,
							},
						],
					},
				]);
			})
			.catch((_) => {
				setLoading(false);
				setError("Error fetching net zero data");
			});
	}, [client, team.id]);

	return (
		<Card title="Net Zero Journey" className="net-zero-journey">
			{loading ? (
				<Preloader />
			) : error ? (
				<Message error>{error}</Message>
			) : (
				<>
					<ChartLegend />
					<div className="chart-container">
						<CO2Chart {...commonChartProps} data={co2Data} />
						<BudgetRequireChart {...commonChartProps} data={budgetRequiredData} />
					</div>
					<InitiativeButtons />
				</>
			)}
		</Card>
	);
};

export default NetZeroJourney;
