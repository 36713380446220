interface DirectionArrowVectorProps {
	rotation?: number;
	colour?: string;
}

export default function DirectionArrowVector({ rotation = 0, colour = "#03C52E" }: DirectionArrowVectorProps) {
	const rotationString = `rotate(${Number(rotation)})`;
	return (
		<svg
			transform={rotationString}
			width="10"
			height="6"
			viewBox="0 0 10 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 5.5L5 0.5L10 5.5L0 5.5Z" fill={colour} />
		</svg>
	);
}
