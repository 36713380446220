import React, { useEffect, useReducer, useState, useMemo, useRef, MouseEvent } from "react";
import { Accordion, Grid, Message, Icon, List, Form, AccordionTitleProps } from "semantic-ui-react";

import Input from "../UI/Input/Input";
import Toggle from "../UI/Toggle/Toggle";
import Checkbox from "../UI/Checkbox/Checkbox";
import Map from "./Map";
import GroupSelect from "../UI/GroupSelect/GroupSelect";
import FreehandDrawControl from "./FreehandDrawControl";

import useFilteredFeatureCollection, { UseFilteredFeatureCollectionProps } from "../../hooks/useFilteredFeatureCollection";
import SelectedPropertiesOverlay from "../UI/SelectedPropertiesOverlay/SelectedPropertiesOverlay";
import PropertyPopup from "./PropertyPopup";
import Property from "../../rest/Property";
import { UNCLUSTERED_PROPERTIES_LAYER_ID } from "./layers";
import HoveredFeaturesOverlay, { HoveredLayerFeatures } from "../UI/HoveredFeaturesOverlay/HoveredFeaturesOverlay";
import Select from "../UI/Select/Select";
import Button from "../UI/Button/Button";
import Right from "../UI/Right/Right";

import "./MapWithFilters.scss";
import usePropertyAggregates from "../../hooks/usePropertyAggregates";
import { Feature, Polygon } from "geojson";
import { MapLayerMouseEvent, MapboxGeoJSONFeature } from "mapbox-gl";
import { MapRef } from "react-map-gl";
import { Group } from "../../types/types";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const OFF_GAS_LAYER_IDS = ["offgasdata"];

const DEPRIVATION_LAYER_IDS = ["deprivation_scotland", "deprivation_england", "deprivation_wales"];

const initialFilterState: UseFilteredFeatureCollectionProps = {
	address: "",
	groups: [],
	grades: {
		A: false,
		B: false,
		C: false,
		D: false,
		E: false,
		F: false,
		G: false
	},
	detachment: [],
	wallType: [],
	roofType: [],
	fuelType: [],
	propertyType: [],
	polygons: []
};

export default function MapWithFilters() {
	/////////////////////////////
	// HOOKS, STATES, REFS ETC //
	/////////////////////////////

	// TODO: consider putting filters into a neater data structure as they will all get updated together - Reducer is probably appropriate here, would be nice to make the filters dynamic too
	const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);

	const [filterState, filterDispatch] = useReducer(
		(state: UseFilteredFeatureCollectionProps, action: {type: string, value?: any}): UseFilteredFeatureCollectionProps => {

		switch(action.type)
		{
			case "reset":
				return initialFilterState;
			
			default:
				return {
					...state,
					[action.type]: action.value
				};
		}

	}, initialFilterState);

    // Could you type all of these?

	const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
	const [hoveredLayerFeatures, setHoveredLayerFeatures] 
        = useState< HoveredLayerFeatures | null>(null);
	const [mapboxMap, setMapboxMap] = useState<MapRef | null>(null);
	const drawingControlRef = useRef<MapboxDraw | null>(null);
	const [drawingFeatures, setDrawingFeatures] = useState<Feature<Polygon>[]>([]); // the drawn polygons
	const [offGasLayer, setOffGasLayer] = useState(false);
	const [deprevationLayer, setDeprevationLayer] = useState(false);

	const {
		properties: filteredFeatureCollection, 
		updateFilters: onApplyFilters,
		isFilterLoading
	} = useFilteredFeatureCollection(
		filterState
	);

	const aggregates = usePropertyAggregates();

	// NB: Auto apply filters when the user changes the polygons
	// NB: Ignore the deps, it would be better to use useCallback above but this broke the isFilterLoading behaviour
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => onApplyFilters(), [filterState.polygons]);

	const isLoading = filteredFeatureCollection.isLoading || isFilterLoading;

	const filteredProperties = useMemo(() => {
		return filteredFeatureCollection.features.map(feature => feature.properties.data);
	}, [filteredFeatureCollection]);
		
	const valuesToSelectOptions = (values: string[] | undefined) => {
		if(!values)
			return [];

		return values.map(value => {
			return {
				value,
				text: value,
				key: value
			};
		})
	};

	const toggleLayerVisibility = (layerIds: string[]) => {
		const map = mapboxMap?.getMap();

		if (!map) throw new Error("Map not ready yet");

		// NB: Accept a single layer as an argument, or an array of layers
		if (!Array.isArray(layerIds)) layerIds = [layerIds];

		const first = map.getLayer(layerIds[0]);

		if (!first) throw new Error("Could not find layer " + layerIds[0]);

		const isVisible = !(
			map.getLayoutProperty(first.id, "visibility") === "undefined" ||
			map.getLayoutProperty(first.id, "visibility") === "none"
		);

		const visibility = isVisible ? "none" : "visible";

		layerIds.forEach((layer) => {
			map.setLayoutProperty(layer, "visibility", visibility);
		});
	};

	///////////////
	// CALLBACKS //
	///////////////
	const onAccordionClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, titleProps: AccordionTitleProps) => {
		const { index } = titleProps;
		setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index as number);
	};

	const onAddressChange = (address: string) => {
		filterDispatch({
			type: "address",
			value: address.length ? address : null
		});
	};

	const onGroupChange = (groups: Group[]) => {
		filterDispatch({
			type: "groups",
			value: groups.map(group => group.id)
		});
	};

	const onGradeChange = (grade: string, checked: boolean | undefined) => {
		filterDispatch({
			type: "grades",
			value: {
				...filterState.grades,
				[grade]: checked
			}
		});
	};

	const onDetachmentChange = (value: string[]) => {
		filterDispatch({
			type: "detachment",
			value
		});
	};

	const onWallTypeChange = (value: string[]) => {
		filterDispatch({
			type: "wallType",
			value
		});
	};

	const onRoofTypeChange = (value: number[]) => {
		filterDispatch({
			type: "roofType",
			value
		});
	};

	const onFuelTypeChange = (value: string[]) => {
		filterDispatch({
			type: "fuelType",
			value
		});
	};

	const onPropertyTypeChange = (value: string[]) => {
		filterDispatch({
			type: "propertyType",
			value
		})
	};

	// TODO: The table should be updated
	const onDrawingFeaturesChanged = (features: Feature<Polygon>[]) => {
		setDrawingFeatures(features);
		filterDispatch({
			type: "polygons",
			value: features
		});
	};

	const onCloseSelectedProperties = () => {
		drawingControlRef.current?.deleteAll();
		setDrawingFeatures([]);
		filterDispatch({
			type: "polygons",
			value: []
		});
	};

	const onClearFilters = () => {
		drawingControlRef.current?.deleteAll();
		setDrawingFeatures([]);
		filterDispatch({
			type: "reset"
		});
	};

	const onClick = (event: MapLayerMouseEvent) => {
		const propertyFeature = event.features?.find((obj) => obj.layer?.id === UNCLUSTERED_PROPERTIES_LAYER_ID);

		if (!propertyFeature) {
			setSelectedProperty(null);
			return;
		}

		// NB: Bit of a run around here, MapBox won't let us store objects (or nested data) in the properties, it always serializes it into JSON. So we must parse here
		const property = new Property( JSON.parse(propertyFeature?.properties?.data) );

		setSelectedProperty(property);
	};

	const onMapReady = (map: MapRef) => {
		setMapboxMap(map);
	};

	const onToggleOffGasLayer = () => {
		setOffGasLayer(!offGasLayer);
		if (deprevationLayer) onToggleDeprivationLayer();
		toggleLayerVisibility(OFF_GAS_LAYER_IDS);
	};

	const onToggleDeprivationLayer = () => {
		setDeprevationLayer(!deprevationLayer);
		if (offGasLayer) onToggleOffGasLayer();
		toggleLayerVisibility(DEPRIVATION_LAYER_IDS);
	};

	const onMouseMove = (event: MapLayerMouseEvent) => {

		const layers = {
			offGas: OFF_GAS_LAYER_IDS,
			deprivation: DEPRIVATION_LAYER_IDS,
		};

		const featuresByLayerKey: HoveredLayerFeatures = {};

		for (const key in layers) {
			const map: { [key: string]: boolean } = {};

			// NB: Faster lookup than indexOf
			layers[key as keyof typeof layers].forEach((value: string) => {
				map[value] = true;
			});

			const feature = event.features?.find((feature) => map[feature.layer.id]);

			if (!feature) continue;

			featuresByLayerKey[key as keyof HoveredLayerFeatures] = feature as MapboxGeoJSONFeature & { properties: Record<string, string> };
		}

		if(JSON.stringify(featuresByLayerKey) === JSON.stringify(hoveredLayerFeatures))
			return;

		setHoveredLayerFeatures(featuresByLayerKey);

	};

	////////////
	// RENDER //
	////////////
	return (
		<>
			{filteredFeatureCollection.error && <Message negative>{filteredFeatureCollection.error}</Message>}

			<Accordion fluid styled className="mapping-accordion">

				<Accordion.Title
					active={activeAccordionIndex === 0}
					index={0}
					onClick={onAccordionClick}
				>
					<Icon name="dropdown" />
					Layers
				</Accordion.Title>
				<Accordion.Content active={activeAccordionIndex === 0}>
					<List>
						<List.Item>
							<Toggle
								label="Enable Off-Gas Map Layer"
								onChange={onToggleOffGasLayer}
								checked={offGasLayer}
							/>
						</List.Item>
						<List.Item>
							<Toggle
								label="Enable Deprivation Map Layer"
								onChange={onToggleDeprivationLayer}
								checked={deprevationLayer}
							/>
						</List.Item>
					</List>
				</Accordion.Content>

				<Accordion.Title
					active={activeAccordionIndex === 1}
					index={1}
					onClick={onAccordionClick}
				>
					<Icon name="dropdown" />
					Filters
				</Accordion.Title>
				<Accordion.Content active={activeAccordionIndex === 1}>
					<Form className="mapping-filters">
						<Form.Field>
							<label>Address</label>
							<Input 
								disabled={isLoading}
								value={filterState.address}
								onChange={(event) => onAddressChange(event.target.value)} 
							/>
						</Form.Field>
						<Form.Field>
							<label>Group</label>
							<GroupSelect
								multiple
								currentlySelected={filterState.groups}
								onChange={(data: Group[]) => onGroupChange(data)}
							/>
						</Form.Field>
						<Form.Field>
							<label>Wall Type</label>
							<Select
								multiple
								disabled={isLoading}
								options={valuesToSelectOptions(aggregates?.values.wall_types)}
								value={filterState.wallType}
								onChange={(event, { value }) => onWallTypeChange(value as string[])}
							/>
						</Form.Field>
						<Form.Field>
							<label>Roof Type</label>
							<Select
								multiple
								disabled={isLoading}
								options={valuesToSelectOptions(aggregates?.values.roof_types)}
								value={filterState.roofType}
								onChange={(event, { value }) => onRoofTypeChange(value as number[])}
							/>
						</Form.Field>
						<Form.Field>
							<label>Detachment Type</label>
							<Select
								multiple
								disabled={isLoading}
								options={valuesToSelectOptions(aggregates?.values.detachments)}
								value={filterState.detachment}
								onChange={(event, { value }) => onDetachmentChange(value as string[])}
							/>
						</Form.Field>
						<Form.Field>
							<label>Property Type</label>
							<Select
								multiple
								disabled={isLoading}
								options={valuesToSelectOptions(aggregates?.values.property_types)}
								value={filterState.propertyType}
								onChange={(event, { value }) => onPropertyTypeChange(value as string[])}
							/>
						</Form.Field>
						<Form.Field>
							<label>Fuel Type</label>
							<Select
								multiple
								disabled={isLoading}
								options={valuesToSelectOptions(aggregates?.values.fuel_types)}
								value={filterState.fuelType}
								onChange={(event, { value }) => onFuelTypeChange(value as string[])}
							/>
						</Form.Field>
					</Form>

					<label className="epc-rating">
						EPC Rating
					</label>
					<div className="checkbox-container">
						{["A", "B", "C", "D", "E", "F", "G"].map((grade) => {
							return (
								<React.Fragment key={grade}>
									<Grid.Column width={1} floated="right">
										<Checkbox
											checked={filterState.grades?.[grade]}
											label={grade}
											epcRatingGrade={grade}
											disabled={isLoading}
											onChange={(event, data) => onGradeChange(grade, data.checked)}
										/>
									</Grid.Column>
								</React.Fragment>
							);
						})}
					</div>

					<Right>
						<Button 
							disabled={isLoading}
							variant="outlined" 
							onClick={onClearFilters}
						>
							Clear
						</Button>
						<Button 
							disabled={isLoading}
							variant="contained" 
							onClick={onApplyFilters}
						>
							Apply
						</Button>
					</Right>
				</Accordion.Content>

			</Accordion>

			<Map
				properties={filteredFeatureCollection}
				interactiveLayerIds={[...OFF_GAS_LAYER_IDS, ...DEPRIVATION_LAYER_IDS]}
				onReady={onMapReady}
				onMouseMove={onMouseMove}
				onClick={onClick}
				onDrawingFeaturesChanged={onDrawingFeaturesChanged}
				drawingFeatures={drawingFeatures}
			>
				<FreehandDrawControl
					position="top-right"
					displayControlsDefault={false}
					controls={{
						polygon: true,
						trash: true,
					}}
					onReady={(instance: MapboxDraw) => drawingControlRef.current = instance}
					mapGl={mapboxMap}
				/>
				{selectedProperty && (
					<PropertyPopup
						property={selectedProperty}
						onClose={() => setSelectedProperty(null)}
					/>
				)}
				<div className="overlays">
					<HoveredFeaturesOverlay features={hoveredLayerFeatures} />
					{!!filterState.polygons && filterState.polygons.length > 0 && (
						<SelectedPropertiesOverlay
							properties={filteredProperties}
							isLoading={isLoading}
							onClose={() => onCloseSelectedProperties()}
						/>
					)}
				</div>
			</Map>
		</>
	);
}
