export default function TripleGlazingVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1371"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1371)">
				<path
					d="M12 14L1 8L12 2L23 8L12 14ZM12 18L1.575 12.325L3.675 11.175L12 15.725L20.325 11.175L22.425 12.325L12 18ZM12 22L1.575 16.325L3.675 15.175L12 19.725L20.325 15.175L22.425 16.325L12 22ZM12 11.725L18.825 8L12 4.275L5.175 8L12 11.725Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
