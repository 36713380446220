import React, { useEffect, useState } from "react";
import { Grid, Message, MessageContent, MessageHeader } from "semantic-ui-react";
import Card from "../../UI/Card/Card";
import FavouriteGroupCard from "./FavouriteGroupCard";
import Preloader from "../../UI/Preloader/Preloader";

import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useTeamContext } from "../../../contexts/TeamContext";

import { Group } from "../../../types/types";

import "./FavouriteGroups.scss";
import { Link } from "../../../shims/useNavigate";

const FavouriteGroups = () => {
	const client = useRestClientContext();
	const team = useTeamContext();

	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState<Array<Group>>();
	const [error, setError] = useState<string>("");

	// TODO: Repeated from Groups.js. Would be great to have some API methods for this. Or make a hook.
	useEffect(() => {
		setLoading(true);
		setError("");

		// TODO: Limit this to six on the server, this isn't terribly efficient
		client
			.fetchGroups(team.id)
			.then((response: Response) => response.json())
			.then((json: Array<Group>) => {
				setGroups(json.slice(0, 6));
				setLoading(false);
			})
			.catch((_) => {
				setLoading(false);
				setError("Error fetching favourite groups");
			});
	}, [client, team.id]);

	return (
		<Card title="Favourite Groups" minHeight={357}>
			{loading ? (
				<Preloader />
			) : error ? (
				<Message error>{error}</Message>
			) : (
				<Grid className="folder-grid" stackable columns={2} padded="vertically">
					{groups?.length ? (
						groups.map((group) => (
							<Grid.Column width={8} key={group.id}>
								<FavouriteGroupCard
									key={group.id}
									id={group.id}
									name={group.name}
									date={group.inserted_at}
								/>
							</Grid.Column>
						))
					) : (
						<Message>
							<MessageHeader>No favourite groups found</MessageHeader>
							<MessageContent>
								Add a favourite group from the <Link to={"/groups"}>groups page</Link>
							</MessageContent>
						</Message>
					)}
				</Grid>
			)}
		</Card>
	);
};

export default FavouriteGroups;
