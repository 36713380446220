import AirHeatPumpVector from "../../../assets/CustomImprovementIcons/AirHeatPumpVector";
import BlockChimneyVector from "../../../assets/CustomImprovementIcons/BlockChimneyVector";
import BoilerFlameVector from "../../../assets/CustomImprovementIcons/BoilerFlameVector";
import ConvertArrowsVector from "../../../assets/CustomImprovementIcons/ConvertArrowsVector";
import DoorVector from "../../../assets/CustomImprovementIcons/DoorVector";
import DoubleGlazingVector from "../../../assets/CustomImprovementIcons/DoubleGlazingVector";
import DraughtWindVector from "../../../assets/CustomImprovementIcons/DraughtWindVector";
import ExternalWallInsulationVector from "../../../assets/CustomImprovementIcons/ExternalWallInsulationVector";
import FloorVentsVector from "../../../assets/CustomImprovementIcons/FloorVentsVector";
import GroundHeatPumpVector from "../../../assets/CustomImprovementIcons/GroundHeatPumpVector";
import HeatingControlsVector from "../../../assets/CustomImprovementIcons/HeatingControlsVector";
import LightingVector from "../../../assets/CustomImprovementIcons/LightingVector";
import LoftInsulationVector from "../../../assets/CustomImprovementIcons/LoftInsulationVector";
import PartyWallInsulationVector from "../../../assets/CustomImprovementIcons/PartyWallInsulationVector";
import PerformanceGlazingVector from "../../../assets/CustomImprovementIcons/PerfomanceGlazingVector";
import PvVector from "../../../assets/CustomImprovementIcons/PvVector";
import SmartHeatingControlsVector from "../../../assets/CustomImprovementIcons/SmartHeatingControlsVector";
import SolarHeatingVector from "../../../assets/CustomImprovementIcons/SolarHeatingVector";
import StorageHeatersVector from "../../../assets/CustomImprovementIcons/StorageHeatersVector";
import TripleGlazingVector from "../../../assets/CustomImprovementIcons/TripleGlazingVector";
import UnderfloorInsulationVector from "../../../assets/CustomImprovementIcons/UnderfloorInsulationVector";
import WallInsulationVector from "../../../assets/CustomImprovementIcons/WallInsulationVector";
import WaterInsulationVector from "../../../assets/CustomImprovementIcons/WaterInsulationVector";
import { CustomImprovementsSet, SapImprovementTypes } from "../../../types/types";
import { CardItem } from "../../UI/Card/CardItem";
import SmartTargetValueChip from "./SmartTargetValueChip";

import "./SmartTargetResultItem.scss";

interface SmartTargetResultItemProps {
	improvementResult: CustomImprovementsSet;
	currentSapResults?: CustomImprovementsSet[] | null;
}

// grade types have been removed (means that these are all number types)
type ImprovementType = {
	name: keyof Omit<SapImprovementTypes, "co2_rating" | "sap_rating">;
	positive: boolean;
};
const improvementTypes: ImprovementType[] = [
	{ name: "energy_saving", positive: true },
	{ name: "co2_saving", positive: true },
	{ name: "cost_saving", positive: true },
	{ name: "sap_value", positive: true },
	{ name: "co2_value", positive: false },
	{ name: "heat_demand", positive: false },
	{ name: "hlp", positive: true },
];

export default function SmartTargetResultItem({ improvementResult, currentSapResults }: SmartTargetResultItemProps) {
	const currentSapResult = currentSapResults
		? currentSapResults.find((set: CustomImprovementsSet) => set.name === improvementResult.name)
		: null;

	const fetchIcon = () => {
		// remove any bracketed additions to the name
		const baseImprovementName = improvementResult.name.split("(")[0].trim().toLowerCase();
		switch (baseImprovementName) {
			case "100% low energy lighting":
				return <LightingVector />;
			case "air source heat pump":
				return <AirHeatPumpVector />;
			case "a rated boiler":
				return <BoilerFlameVector />;
			case "block open chimneys":
				return <BlockChimneyVector />;
			case "convert main heating to gas":
				return <ConvertArrowsVector />;
			case "double glazing a-rated":
				return <DoubleGlazingVector />;
			case "draught proofing improvements":
				return <DraughtWindVector />;
			case "external wall insulation":
				return <ExternalWallInsulationVector />;
			case "ground source heat pump":
				return <GroundHeatPumpVector />;
			case "heating controls":
				return <HeatingControlsVector />;
			case "high heat retention storage heaters":
				return <StorageHeatersVector />;
			case "high performance doors":
				return <DoorVector />;
			case "high performance glazing":
				return <PerformanceGlazingVector />;
			case "improve hot water cylinder insulation":
				return <WaterInsulationVector />;
			case "loft insulation to 270mm":
			case "loft insulation to 400mm":
				return <LoftInsulationVector />;
			case "party wall insulation":
				return <PartyWallInsulationVector />;
			case "pv panels":
			case "pv":
				return <PvVector />;
			case "smart floor vents":
				return <FloorVentsVector />;
			case "smart heating controls":
				return <SmartHeatingControlsVector />;
			case "solar water heating":
				return <SolarHeatingVector />;
			case "triple glazing":
				return <TripleGlazingVector />;
			case "under floor insulation":
				return <UnderfloorInsulationVector />;
			case "wall insulation":
				return <WallInsulationVector />;
			default:
				// no icon available
				return undefined;
		}
	};

	/**
	 * Is the difference a positive or negative thing
	 * @param improvement
	 */
	const compareResult = (improvement: ImprovementType) => {
		if (!currentSapResult) {
			return null;
		}

		const improvementValue = improvementResult.result[improvement.name];
		const currentValue = currentSapResult.result[improvement.name];

		if (improvementValue < currentValue) {
			return !improvement.positive;
		} else if (improvementValue === currentValue) {
			return null;
		}
		return improvement.positive;
	};

	/**
	 * Calculate the difference in value of number or grade between current result and the found improvement
	 * @param improvement
	 */
	const calcDifference = (improvement: keyof SapImprovementTypes) => {
		if (!currentSapResult) {
			return null;
		}

		const improvementValue = improvementResult.result[improvement];
		const currentValue = currentSapResult.result[improvement];
		const grades = ["A", "B", "C", "D", "E", "F", "G"];

		if (typeof improvementValue === "number" && typeof currentValue === "number") {
			return improvementValue - currentValue;
		} else if (
			typeof improvementValue === "string" &&
			grades.includes(improvementValue) &&
			typeof currentValue === "string" &&
			grades.includes(currentValue)
		) {
			return grades.indexOf(currentValue) - grades.indexOf(improvementValue);
		}
		return null;
	};

	return (
		<CardItem title={improvementResult.name} icon={fetchIcon()} className="smart-target-result-item">
			<div className="result-item-container">
				{improvementTypes.map((improvementType) => (
					<SmartTargetValueChip
						key={improvementType.name}
						type={improvementType.name}
						value={calcDifference(improvementType.name)}
						direction={compareResult(improvementType)}
					/>
				))}
			</div>
		</CardItem>
	);
}
