import Property from "../../rest/Property";
import Card from "../UI/Card/Card";
import EmblaCarousel from "../UI/Carousel/EmblaCarousel";

import "./PropertyImagesCard.scss";

interface PropertyImagesCardProps {
	property: Property;
}

export default function PropertyImagesCard({ property }: PropertyImagesCardProps) {
	return (
		<Card title="Property Images" className="property-thermal-images">
			{property.data.images && property.data.images.length > 0 ? (
				<EmblaCarousel slides={property.data.images} />
			) : (
				<p>No images available</p>
			)}
		</Card>
	);
}
