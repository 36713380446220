import React, { useState } from "react";

import { Grid, GridColumn, GridRow, Input, List, ListItem, Segment } from "semantic-ui-react";
import SearchListVector from "../../assets/SearchListVector";
import { SmartTargetFilter } from "../../types/types";
import SmartTargetFilterItem, {
	SmartTargetComparison,
	smartTargetComparisons,
} from "../CustomImprovements/SmartTargets/SmartTargetFilterItem";
import Button from "../UI/Button/Button";
import Modal from "./Modal";

import "./SmartTargetFilterModal.scss";

interface SmartTargetFilterModalProps {
	onClose: () => void;
	onSaveFilters: (filters: SmartTargetFilter[]) => void;
	improvementState: SmartTargetFilter[];
	nested?: boolean;
}

export default function SmartTargetFilterModal({
	onClose,
	onSaveFilters,
	improvementState,
	nested = true,
}: SmartTargetFilterModalProps) {
	const [dirtyFilters, setDirtyFilters] = useState<SmartTargetFilter[]>(improvementState);
	const [searchValue, setSearchValue] = useState<string>("");

	const onCancel = () => {
		setDirtyFilters(improvementState);
		onClose();
	};

	const onSave = () => {
		// const filteredFilters = dirtyFilters.filter((filter) => filter.hidden || (filter.value && filter.operator));
		onSaveFilters(dirtyFilters);
		onClose();
	};

	const onHideToggle = (filter: SmartTargetFilter, isChecked: boolean) => {
		let updatedFilters = [...dirtyFilters];
		const filterElement = dirtyFilters.findIndex((f) => f.customImprovement.id === filter.customImprovement.id);
		updatedFilters[filterElement] = { ...updatedFilters[filterElement], hidden: !isChecked };
		setDirtyFilters(updatedFilters);
	};

	const onSettingsChange = (filter: SmartTargetFilter, operator: string | null, value: string | null) => {
		const comparator = operator as SmartTargetComparison;
		if (
			isNaN(Number(value)) ||
			!smartTargetComparisons.includes(comparator) ||
			!filter.customImprovement.accepts_parameters
		) {
			// we shouldn't ever get here with validation (and error reporting) on child component
			console.error("Invalid state");
			return;
		}

		let updatedFilters = [...dirtyFilters];
		const filterElement = dirtyFilters.findIndex((f) => f.customImprovement.id === filter.customImprovement.id);
		updatedFilters[filterElement] = {
			...updatedFilters[filterElement],
			operator: comparator,
			value: Number(value),
		};
		setDirtyFilters(updatedFilters);
	};

	return (
		<Modal title={"Included Measures"} nested={nested} onClose={() => onClose()}>
			<Input
				placeholder="Search Measures..."
				icon={<SearchListVector className="search-icon" />}
				iconPosition="left"
				className="search-measures"
				onChange={(e) => {
					setSearchValue(e.target.value);
				}}
			/>
			<Segment className="filter-item-list-container">
				<List>
					{dirtyFilters
						.filter((state) =>
							state.customImprovement.name.toLowerCase().includes(searchValue.toLowerCase())
						)
						.map((filter) => (
							<ListItem key={filter.customImprovement.id}>
								<SmartTargetFilterItem
									filterState={filter}
									onToggle={(isChecked) => {
										onHideToggle(filter, isChecked);
									}}
									onSettingsChange={(comparison, value) => {
										onSettingsChange(filter, comparison, value);
									}}
								/>
							</ListItem>
						))}
				</List>
			</Segment>
			<Grid>
				<GridRow>
					<GridColumn width={8}>
						<Button variant={"modal-secondary"} onClick={() => onCancel()}>
							Cancel
						</Button>
					</GridColumn>
					<GridColumn width={8}>
						<Button variant={"modal-primary"} onClick={() => onSave()}>
							Save Filters
						</Button>
					</GridColumn>
				</GridRow>
			</Grid>
		</Modal>
	);
}
