export default function ExternalWallInsulationVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1285"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1285)">
				<circle cx="7" cy="9" r="1" fill="#1C1B1F" />
				<circle cx="7" cy="15" r="1" fill="#1C1B1F" />
				<circle cx="12" cy="12" r="1" fill="#1C1B1F" />
				<circle cx="17" cy="9" r="1" fill="#1C1B1F" />
				<circle cx="17" cy="15" r="1" fill="#1C1B1F" />
				<path
					d="M4.22222 20C3.61111 20 3.08796 19.8259 2.65278 19.4778C2.21759 19.1296 2 18.7111 2 18.2222V5.77778C2 5.28889 2.21759 4.87037 2.65278 4.52222C3.08796 4.17407 3.61111 4 4.22222 4H19.7778C20.3889 4 20.912 4.17407 21.3472 4.52222C21.7824 4.87037 22 5.28889 22 5.77778V18.2222C22 18.7111 21.7824 19.1296 21.3472 19.4778C20.912 19.8259 20.3889 20 19.7778 20H4.22222ZM4.22222 18.2222H19.7778V5.77778H4.22222V18.2222Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
