import React, { useEffect, useState } from "react";

import {
	Divider,
	Grid,
	GridColumn,
	GridRow,
	Header,
	Message,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from "semantic-ui-react";
import { useUserContext } from "../../contexts/UserContext";
import {
	CustomImprovementsSet,
	SapImprovementNiceNames,
	sapImprovementUnitsMap,
	SmartTarget,
	SmartTargetFields,
	SmartTargetLogEntry,
} from "../../types/types";
import SmartTargetResultItem from "../CustomImprovements/SmartTargets/SmartTargetResultItem";
import Button from "../UI/Button/Button";
import { CardItem } from "../UI/Card/CardItem";
import Preloader from "../UI/Preloader/Preloader";
import Select from "../UI/Select/Select";
import Modal from "./Modal";

import "./SmartTargetResultModal.scss";

function RenderMessageAsTable({ message }: { message: string }) {
	const tableData = JSON.parse(message);

	return (
		<Table celled>
			<TableHeader>
				<TableRow>
					{Object.keys(tableData[0]).map((key: string) => (
						<TableHeaderCell key={key}>{key}</TableHeaderCell>
					))}
				</TableRow>
			</TableHeader>

			<TableBody>
				{tableData.map((row: any, iter: number) => (
					<TableRow key={iter}>
						{Object.keys(row).map((cellKey: string) => (
							<TableCell key={cellKey}>{row[cellKey]}</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

const secondaryResultFields = Object.values(SmartTargetFields).filter(
	// cost saving is the primary displayed field
	(field) => field !== SmartTargetFields.COST_SAVING
);
const filterOptions = [
	{ key: "debug", value: 3, text: "Debug" },
	{ key: "detail", value: 2, text: "Detailed" },
	{ key: "overview", value: 1, text: "Overview" },
];
const initialLogLevel = 1;

interface SmartTargetResultModalProps {
	smartTarget: SmartTarget;
	currentImprovementSets: CustomImprovementsSet[];
	updateSmartTarget: (smartTarget: SmartTarget) => Promise<SmartTarget>;
	onClose: () => void;
}

export default function SmartTargetResultModal({
	smartTarget,
	currentImprovementSets,
	updateSmartTarget,
	onClose,
}: SmartTargetResultModalProps) {
	const [logsOpen, setLogsOpen] = useState<boolean>(false);
	const [filteredLogs, setFilteredLogs] = useState<SmartTargetLogEntry[]>([]);

	const user = useUserContext();

	useEffect(() => {
		updateSmartTarget(smartTarget).then((smartTarget) => filterLogs(initialLogLevel, smartTarget.logs));
		// only update when the id changes - we don't want to end up in a useEffect loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [smartTarget.id]);

	const filterLogs = (level: number, logs?: SmartTargetLogEntry[]) => {
		if (logs?.length) {
			setFilteredLogs(logs.filter((log: SmartTargetLogEntry) => log.log_level <= level));
		}
		if (smartTarget.logs) {
			setFilteredLogs(smartTarget.logs.filter((log: SmartTargetLogEntry) => log.log_level <= level));
		}
	};

	const isTableData = (message: string): boolean => {
		if (message.startsWith("[{")) {
			try {
				JSON.parse(message);
				return true;
			} catch (e) {
				return false;
			}
		}

		return false;
	};

	return (
		<Modal title="Suggested Improvements" onClose={onClose} size="large">
			{smartTarget.custom_improvement_sets ? (
				<>
					<Grid>
						{smartTarget.custom_improvement_sets.map((impSet) => (
							<GridRow key={impSet.id}>
								<GridColumn width={8}>
									<SmartTargetResultItem
										improvementResult={impSet}
										currentSapResults={currentImprovementSets}
									/>
								</GridColumn>
							</GridRow>
						))}
					</Grid>

					<Header as="h3">Inputs</Header>
					<Divider />
					<Table className="secondary-table-theme">
						<TableHeader>
							<TableRow>
								<TableHeaderCell>Target Field</TableHeaderCell>
								<TableHeaderCell>Current Value</TableHeaderCell>
								<TableHeaderCell>Target Value</TableHeaderCell>
								<TableHeaderCell>Achieved</TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{smartTarget.custom_improvement_sets.map((impSet) => {
								const matchCurrentImprovement = currentImprovementSets.filter(
									(set) => set.name === impSet.name
								);

								return (
									<TableRow key={impSet.id}>
										<TableCell>{SapImprovementNiceNames[smartTarget.target]}</TableCell>
										<TableCell>
											{matchCurrentImprovement.length
												? matchCurrentImprovement[0].result[smartTarget.target]
												: "N/A"}
										</TableCell>
										<TableCell>{smartTarget.value}</TableCell>
										<TableCell>{impSet.result[smartTarget.target]}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>

					<Header as="h3">SAP Results</Header>
					<Divider />
					{smartTarget.custom_improvement_sets.length ? (
						<Grid stretched columns={2}>
							<GridColumn width={5}>
								<GridRow>
									<CardItem title="Cost Saving" className="result-primary">
										<b>
											{sapImprovementUnitsMap[SmartTargetFields.COST_SAVING].unit}
											{smartTarget.custom_improvement_sets[0].result.cost_saving}
										</b>
										<span className="result-time-frame">
											{sapImprovementUnitsMap[SmartTargetFields.COST_SAVING].timeFrame
												? " / " +
												  sapImprovementUnitsMap[SmartTargetFields.COST_SAVING].timeFrame
												: ""}
										</span>
									</CardItem>
								</GridRow>
							</GridColumn>
							<GridColumn width={11}>
								<div className="smart-target-result-box-container">
									{secondaryResultFields.map((field) => (
										<CardItem
											key={field}
											title={SapImprovementNiceNames[field]}
											className="result-secondary"
										>
											<b>
												{smartTarget.custom_improvement_sets![0].result[field]}
												{sapImprovementUnitsMap[field].unit
													? " " + sapImprovementUnitsMap[field].unit
													: ""}
											</b>
											<span className="result-time-frame">
												{sapImprovementUnitsMap[field].timeFrame
													? ` / ${sapImprovementUnitsMap[field].timeFrame}`
													: ""}
											</span>
										</CardItem>
									))}
								</div>
							</GridColumn>
						</Grid>
					) : (
						<Message>No result found</Message>
					)}

					{/* Not in designs, but will still be useful for debugging issues - only display for admins */}
					{user.is_admin && (
						<>
							<div style={{ marginTop: "2rem" }}>
								<Button variant="contained" onClick={() => setLogsOpen(!logsOpen)}>
									{logsOpen ? "Hide" : "Show"} logs (admin)
								</Button>
							</div>

							{logsOpen && (
								<>
									<Header as="h3">Logs</Header>
									<Divider />

									<Select
										placeholder="Log level"
										options={filterOptions}
										defaultValue={initialLogLevel}
										onChange={(_event, data) => filterLogs(Number(data.value))}
									/>

									{filteredLogs.length > 0 ? (
										<div className="logs-area">
											{filteredLogs.map((entry: SmartTargetLogEntry) => (
												<CardItem key={entry.id} className="smart-target-log">
													<b>{entry.log_time}</b>
													{isTableData(entry.message) ? (
														<RenderMessageAsTable message={entry.message} />
													) : (
														<p>{entry.message}</p>
													)}
												</CardItem>
											))}
										</div>
									) : (
										<Message warning>No logs found</Message>
									)}
								</>
							)}
						</>
					)}
				</>
			) : (
				<Preloader />
			)}
		</Modal>
	);
}
