/* mears-mcmview-override-contents

import { ActivatedRoute } from '@angular/router';
import { useInjected, useObservable } from '@yellow-sub/angular-react';
import { filter } from 'rxjs/operators';

export default function useParams() {
  const route = useInjected(ActivatedRoute);

  const params$ = route.params.pipe(filter(params => params != null));
  const [params] = useObservable(params$);

  if (params == null) return {}

  return params;
}
*/

import { useParams } from 'react-router-dom';

export default useParams;