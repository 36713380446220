import { useMemo } from "react";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";

export default function useProcessImageUploadSpreadsheetFiles({ files }: { files: Blob[] }) {
	const [header, setHeader] = useState<string[]>([]);
	const [records, setRecords] = useState<string[]>([]);
	const [keys, setKeys] = useState<string[]>([]);

	const onSpreadsheetLoaded = useMemo(() => {
		return (event: ProgressEvent<FileReader>) => {
			const workbook = XLSX.read(event.target?.result);
			const keyMap: { [key: string]: any} = {};
			let records: any[] = [];

			for (const key in workbook.Sheets) {
				const sheet = workbook.Sheets[key];
				// @ts-ignore - says function doesn't exist 
				const arr = XLSX.utils.sheet_to_row_object_array(sheet);

				for (const obj of arr)
					Object.keys(obj).forEach((key) => {
						keyMap[key] = true;
					});

				records = [...records, ...arr];

				const rowMajor = XLSX.utils.sheet_to_json(sheet, { header: 1 });
				const first = rowMajor[0];

				setHeader(first as string[]);
			}

			setKeys(Object.keys(keyMap).sort());
			setRecords(records);
		};
	}, []);

	useEffect(() => {
		files.forEach((file: Blob) => {
			const reader = new FileReader();
			reader.onload = onSpreadsheetLoaded;
			reader.readAsArrayBuffer(file);
		});
	}, [files, onSpreadsheetLoaded]);

	return { header, keys, records };
}
