export default function SettingsSlidersVector() {
	return (
		<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.8337 11.5C10.1337 11.5 9.54199 11.2583 9.05866 10.775C8.57533 10.2917 8.33366 9.7 8.33366 9C8.33366 8.3 8.57533 7.70833 9.05866 7.225C9.54199 6.74167 10.1337 6.5 10.8337 6.5C11.5337 6.5 12.1253 6.74167 12.6087 7.225C13.092 7.70833 13.3337 8.3 13.3337 9C13.3337 9.7 13.092 10.2917 12.6087 10.775C12.1253 11.2583 11.5337 11.5 10.8337 11.5ZM10.8337 10.1667C11.1559 10.1667 11.4309 10.0528 11.6587 9.825C11.8864 9.59722 12.0003 9.32222 12.0003 9C12.0003 8.67778 11.8864 8.40278 11.6587 8.175C11.4309 7.94722 11.1559 7.83333 10.8337 7.83333C10.5114 7.83333 10.2364 7.94722 10.0087 8.175C9.78088 8.40278 9.66699 8.67778 9.66699 9C9.66699 9.32222 9.78088 9.59722 10.0087 9.825C10.2364 10.0528 10.5114 10.1667 10.8337 10.1667ZM1.66699 9.66667V8.33333H7.00033V9.66667H1.66699ZM3.16699 5.5C2.46699 5.5 1.87533 5.25833 1.39199 4.775C0.908659 4.29167 0.666992 3.7 0.666992 3C0.666992 2.3 0.908659 1.70833 1.39199 1.225C1.87533 0.741667 2.46699 0.5 3.16699 0.5C3.86699 0.5 4.45866 0.741667 4.94199 1.225C5.42533 1.70833 5.66699 2.3 5.66699 3C5.66699 3.7 5.42533 4.29167 4.94199 4.775C4.45866 5.25833 3.86699 5.5 3.16699 5.5ZM3.16699 4.16667C3.48921 4.16667 3.76421 4.05278 3.99199 3.825C4.21977 3.59722 4.33366 3.32222 4.33366 3C4.33366 2.67778 4.21977 2.40278 3.99199 2.175C3.76421 1.94722 3.48921 1.83333 3.16699 1.83333C2.84477 1.83333 2.56977 1.94722 2.34199 2.175C2.11421 2.40278 2.00033 2.67778 2.00033 3C2.00033 3.32222 2.11421 3.59722 2.34199 3.825C2.56977 4.05278 2.84477 4.16667 3.16699 4.16667ZM7.00033 3.66667V2.33333H12.3337V3.66667H7.00033Z"
				fill="#696969"
			/>
		</svg>
	);
}
