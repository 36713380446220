import { useState, useCallback, useEffect } from "react";
import env from "react-dotenv";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { Header, Message } from "semantic-ui-react";
import { ArrowLeftCircle } from "react-feather";

import { useRestClientContext } from "../contexts/RestClientContext";
import { useUserContext } from "../contexts/UserContext";

import Page from "./Page";
import Preloader from "../components/UI/Preloader/Preloader";
import Property from "../rest/Property";
import Button from "../components/UI/Button/Button";

import AdminToolsCard from "../components/Property/AdminToolsCard";

import "./Property.scss";
import PropertyPanel from "../components/Property/PropertyPanel";

export default function PropertyPage(props) {
	const { id } = useParams();
	const client = useRestClientContext();
	const user = useUserContext();
	const navigate = useNavigate();
	const [error, setError] = useState(null);

	//getting the previous page from url - couldn't get anything without URLSearchParams working bc I think react-router-dom took away the function I wanted
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const from = queryParams.has("from") ? queryParams.get("from") : "epc-data";

	const { isLoaded: isMapLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: env.GOOGLE_MAPS_API_KEY,
	});

	const [property, setProperty] = useState(null);

	// NB: Just ignore this... we want setMap but we don't need to read map at the moment
	// eslint-disable-next-line no-unused-vars
	const [map, setMap] = useState(null);

	const onLoad = useCallback(function callback(map) {
		setMap(map);
	}, []);

	const onUnmount = useCallback(function callback(map) {
		setMap(null);
	}, []);

	useEffect(() => {
		async function fetchData() {
			const response = await client.fetchProperty(user.team_id, id);
			const data = await response.json();

			if (data.error) {
				setError(data.error);
				return;
			}

			setProperty(new Property(data));
		}

		setProperty(null);

		try {
			fetchData();
		} catch (e) {
			if (!error) {
				setError(e);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, user.team_id, id]);

	let children;

	if (error) {
		children = (
			<Message negative>
				<Message.Header>{error.code}</Message.Header>
				<p>{error.message}</p>
			</Message>
		);
	}
	else if (!property || !isMapLoaded)
		children = <Preloader status="Loading property..." />;
	else {
		children = (
			<>
				<Header as="h1" className="address-header">{property.fullAddress}</Header>
				{!!property.googleLatLng && (
					<GoogleMap
						mapContainerStyle={{
							width: "100%",
							height: "50vh",
						}}
						center={property.googleLatLng}
						zoom={18}
						onLoad={onLoad}
						onUnmount={onUnmount}
					>
						{/* NB: Using MarkerF because Marker doesn't work presently, see https://github.com/JustFly1984/react-google-maps-api/issues/3019 */}
						<MarkerF position={property.googleLatLng} />
					</GoogleMap>
				)}
				{user.is_admin && <AdminToolsCard property={property} />}
				<PropertyPanel teamId={property.team_id} propertyId={property.id} />
			</>
		);
	}

	return (
		<Page className="property">
			<Button variant="outlined-borderless go-back" onClick={() => navigate("/" + from)}>
				<ArrowLeftCircle color="white" fill="black" size={20} />
				Go Back
			</Button>
			{children}
		</Page>
	);
}
