import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Tab } from "semantic-ui-react";

import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useUserContext } from "../../../contexts/UserContext";
import Team from "../../../rest/Team";
import { BulkCustomImprovementSetsBatch } from "../../../types/types";
import CustomImprovementSetsModal from "../../CustomImprovements/CustomImprovementSetsModal";
import BulkCustomImprovementSetsProgressModal from "../../CustomImprovements/CustomImprovementSetsProgressModal";
import Button from "../Button/Button";
import JobBatchesTable from "../JobBatchesTable/JobBatchesTable";
import Suspense from "../Suspense/Suspense";

import UserManagementTable from "../UserManagementTable/UserManagementTable";

export default function TeamTabs({ showUserManagement = true }) {
	const params = useParams();
	const client = useRestClientContext();
	const user = useUserContext();

	const [promise, setPromise] = useState<Promise<void>>();
	const [team, setTeam] = useState<Team>();
	const [customImprovementSetsModalOpen, setCustomImprovementSetsModalOpen] = useState(false);
	const [currentBulkCustomImprovementSetsBatches, setCurrentBulkCustomImprovementSetsBatches] = useState<
		BulkCustomImprovementSetsBatch[] | null
	>(null);

	useEffect(() => {
		const promise = client
			.fetchTeam(Number(params.team_id))
			.then((response) => response.json())
			.then((json) => setTeam(json));

		setPromise(promise);
	}, [client, params.team_id]);

	// TODO: Repeated from DataTable, abstract this somehow please
	const onBulkCustomImprovementSetsStarted = (batches: BulkCustomImprovementSetsBatch[]) => {
		setCurrentBulkCustomImprovementSetsBatches(batches);
	};

	let panes = [];

	if (team) {
		if (user.is_admin)
			panes.push({
				menuItem: "Custom Measures",
				render: () => (
					<Tab.Pane>
						<Button variant="contained" onClick={() => setCustomImprovementSetsModalOpen(true)}>
							Update all properties
						</Button>
					</Tab.Pane>
				),
			});

		panes.push({
			menuItem: "Job Batches",
			render: () => (
				<Tab.Pane>
					<JobBatchesTable teamId={Number(params.team_id)} />
				</Tab.Pane>
			),
		});

		if (showUserManagement)
			panes.push({
				menuItem: "User Management",
				render: () => (
					<Tab.Pane>
						<UserManagementTable team={team} />
					</Tab.Pane>
				),
			});
	}

	return (
		<Suspense promise={promise}>
			<Header as="h1">{team?.name}</Header>
			<Tab panes={panes} />
			{customImprovementSetsModalOpen && (
				<CustomImprovementSetsModal
					teamId={Number(params.team_id)}
					onClose={() => setCustomImprovementSetsModalOpen(false)}
					onComplete={(batches: BulkCustomImprovementSetsBatch[]) =>
						onBulkCustomImprovementSetsStarted(batches)
					}
				/>
			)}
			{currentBulkCustomImprovementSetsBatches && (
				<BulkCustomImprovementSetsProgressModal
					batches={currentBulkCustomImprovementSetsBatches}
					onClose={() => setCurrentBulkCustomImprovementSetsBatches(null)}
				/>
			)}
		</Suspense>
	);
}
