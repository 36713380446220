import { Popup } from "react-map-gl";
import { Link } from "../../shims/useNavigate";

import Property from "../../rest/Property";

import Button from "../UI/Button/Button";
import EpcRatingTile from "../UI/EpcRatingTile/EpcRatingTile";

interface PropertyPopupProps {
	property: Property;
	onClose: Function;
}

export default function PropertyPopup({ property, onClose }: PropertyPopupProps) {
	// NB: property as in property belonging to an object, as opposed to domestic property plural. Quirk of Mapbox naming and DREam.
	return (
		<Popup key={property.data.id} longitude={property.data.lng} latitude={property.data.lat} anchor="bottom" onClose={() => onClose()}>
			<div className="map-popup-title">
				<h3 className="ui header">Address</h3>
				<div className="ui divider"></div>
			</div>
			<div className="map-popup-content">
				<address>
					{property.data.addr_no},{" " + property.data.addr_street}
					<br />
					{property.data.addr_city}
					<br />
					{property.data.postcode}
				</address>
				<dl>
					<div className="epc-container-map">
						<dt>EPC:</dt>
						<dd>
							<EpcRatingTile grade={property.data.epc_rating_grade} />
						</dd>
					</div>
					<div className="integrity-container-map">
						<dt>Integrity:</dt>
						<dd>{property.data.data_integrity}</dd>
					</div>
				</dl>
			</div>
			<Link
				to={{
					pathname: `/epc-data/${property.id}`,
					search: "?from=mapping",
				}}
				target="_blank"
				rel="noopener noreferrer"
			>
				<Button variant="contained">View Property</Button>
			</Link>
		</Popup>
	);
}
