import { Header } from "semantic-ui-react";

import Page from "./Page";
import ImportTabs from "../components/Import/ImportTabs";

export default function Import() {
	return (
		<Page>
			<Header as="h1">Import (Beta)</Header>
			{/* Passing in a path here so that it can use a different path on MCM */}
			<ImportTabs importMonitorPath="/imports" /> 
		</Page>
	);
}
