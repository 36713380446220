export default function PartyWallInsulationVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1340"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1340)">
				<path d="M12 7.5H11" stroke="#1C1B1F" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M13 10.5H12" stroke="#1C1B1F" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M12 13.5H11" stroke="#1C1B1F" strokeWidth="1.5" strokeLinecap="round" />
				<path d="M13 16.5H12" stroke="#1C1B1F" strokeWidth="1.5" strokeLinecap="round" />
				<rect x="4" y="4" width="4" height="16" rx="1" stroke="#1C1B1F" strokeWidth="2" />
				<rect x="8" y="4" width="8" height="16" stroke="#1C1B1F" strokeWidth="2" />
				<rect x="16" y="4" width="4" height="16" rx="1" stroke="#1C1B1F" strokeWidth="2" />
			</g>
		</svg>
	);
}
