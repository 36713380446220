// @ts-nocheck
// TODO: Please sort this, things are not what they seem with the table library

import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useTable, usePagination, useRowSelect, useFilters, useSortBy } from "react-table";
// import { useSearchParams } from "react-router-dom";
// import ScrollContainer from "react-indiana-drag-scroll";

import { ColumnDefinition } from "./getColumns";

import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useTeamContext } from "../../../contexts/TeamContext";

import ColumnGroupCheckboxes from "./ColumnGroupCheckboxes";
import IndeterminateCheckbox from "../IndeterminateCheckbox/IndeterminateCheckbox";
import DefaultColumnFilter from "./Filters/DefaultColumnFilter";
import Pagination from "../Pagination/Pagination";
import Cell from "./Cell";
import Preloader from "../Preloader/Preloader";
import Button from "../Button/Button";

import getColumns from "./getColumns";
import BulkEditButton from "./BulkEditButton";
import GroupButtons from "../GroupButtons/GroupButtons";

import { useDummyScrollbar } from "../../../hooks/useDummyScrollbar";

import useLocation from "../../../shims/useLocation";
import useSearchParams from "../../../shims/useSearchParams";

import "./DataTable.scss";

import Property from "../../../rest/Property";
import CustomImprovementSetsModal from "../../CustomImprovements/CustomImprovementSetsModal";
import BulkCustomImprovementSetsProgressModal from "../../CustomImprovements/CustomImprovementSetsProgressModal";
import { Batch, BulkCustomImprovementSetsBatch, Group } from "../../../types/types";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import ExportFromTableModal from "../../Modal/ExportFromTableModal";
import GroupSelect from "../GroupSelect/GroupSelect";
import usePropertyAggregates from "../../../hooks/usePropertyAggregates";
import { Message } from "semantic-ui-react";

interface DataTableProps {
	editable: boolean;
	displayIntegrityTiles?: boolean;
}

export interface DataTableSortBy {
	id: string;
	desc?: boolean;
}

interface DataTableState {
	pageIndex: number;
	sortBy?: DataTableSortBy[];
	filters?: {
		id: string;
		value: string;
	}[];
}

export default function DataTable({ editable, displayIntegrityTiles }: DataTableProps) {
	////////////////////////
	// Contexts and hooks //
	////////////////////////
	// const navigate = useNavigate();
	const location: any = useLocation();
	const client = useRestClientContext();
	const team = useTeamContext();
	const [search, setSearch] = useSearchParams();
	const [dummyScrollRef] = useDummyScrollbar();
	const aggregates = usePropertyAggregates();

	const fetchIdRef = useRef(0);

	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState([]);
	const [controlledPageCount, setControlledPageCount] = useState(0);
	const [totalEntries, setTotalEntries] = useState(0);
	const [manualReloadFlag, setManualReloadFlag] = useState(0);
	const [customImprovementSetsModalOpen, setCustomImprovementSetsModalOpen] = useState(false);
	const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [exportFromTableModalOpen, setExportFromTableModalOpen] = useState(false);
	const [currentBulkCustomImprovementSetsBatches, setCurrentBulkCustomImprovementSetsBatches] = useState<BulkCustomImprovementSetsBatch[]>();
	const [selectedGroups, setSelectedGroups] = useState<Groups[]>([]);
	const [error, setError] = useState();

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	// parse the group query parameter - accepts either a single ID or an array of IDs
	const groupIdParams: null | number | number[] = useMemo(() => {
		if (search.get("group")) {
			if (!isNaN(search.get("group"))) {
				return [Number(search.get("group"))]
			} else {
				// should be an array of numbers
				try {
					const arrayParams = search.get("group")?.split(',');
					const parsedParams = arrayParams?.map((stringId) => {
						if (!isNaN(stringId))
							return Number(stringId);
						else
							return null;
					});
					return parsedParams?.filter(Number) || [];
				} catch (e){
					console.warn("Could not parse array params for group IDs.", e);
				}
			}
		}
		return [];
	}, [search]);

	const getConsolidatedFilters = useCallback((filters) => {

		if(!filters)
			return [];

		let result = [...filters];

		if (groupIdParams?.length && filters.findIndex((el) => el?.id === "group_ids") === -1)
			result = [...filters, { id: "group_ids", value: groupIdParams }];

		return result;

	}, [groupIdParams]);

	const initialState: DataTableState = {
		pageIndex: 0,
		sortBy: null,
	};

	if (location?.state?.sortBy) initialState.sortBy = location.state.sortBy;
	else
		initialState.sortBy = [
			{
				id: "uprn",
				desc: false,
			},
		];

	// TODO: Pretty sure this needs to be memoized
	if (location?.state?.filters) initialState.filters = location.state.filters;

	const data = useMemo(() => entries, [entries]);
	const columns = useMemo(() => getColumns(aggregates), [aggregates]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		toggleHideColumn,
		page,
		pageCount,
		setPageSize,
		previousPage,
		nextPage,
		gotoPage,
		selectedFlatRows,
		state,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState,
			defaultColumn,
			pageCount: controlledPageCount,
			manualPagination: true,
			manualFilters: true,
			manualSortBy: true,
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				// Let's make a column for selection
				{
					id: "selection",
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			]);
		}
	);

	const selectedPropertyIds = selectedFlatRows.map((row) => row.original.id);

	/////////////////
	// REST hander //
	/////////////////
	const abortControllerRef = useRef<AbortController>();

	const fetchData = useCallback(
		({ pageSize, pageIndex, filters, sortBy }) => {
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			/*let epcGradeRating = filters.find((obj) => obj.id === "epc_rating_grade")?.value;
			if (epcGradeRating !== search.get("default-epc-rating-grade")) {
				search.delete("default-epc-rating-grade");
				navigate("/epc-data", { replace: true });
			}*/

			// Set the loading state
			setLoading(true);

			if (fetchId === fetchIdRef.current) {
				
				filters = getConsolidatedFilters(filters);

				if (abortControllerRef.current) abortControllerRef.current.abort();

				abortControllerRef.current = new AbortController();

				client
					.fetchTeamProperties(team, {
						page_number: pageIndex + 1,
						page_size: pageSize,
						orderBy: JSON.stringify(sortBy),
						filters: JSON.stringify(filters)
					})
					.then((response: Response) => response.json())
					.then((json) => {
						setEntries(json.entries.map((data) => new Property(data)));
						setControlledPageCount(json.total_pages);
						setTotalEntries(json.total_entries);
						setLoading(false);
					})
					.catch((error) => {
						setLoading(false);
						setError("Error fetching data")
					});

			}
		},
		[client, team, getConsolidatedFilters]
	);

	useEffect(() => {
		fetchData({
			pageSize,
			pageIndex,
			filters: state.filters,
			sortBy: state.sortBy,
		});
	}, [state.filters, state.sortBy, pageIndex, pageSize, fetchData, manualReloadFlag]);

	/////////////////////
	// Event listeners //
	/////////////////////
	const onGroupVisibilityChanged = ({ columns }: { columns: ColumnDefinition[] }) => {
		for (const column of columns) toggleHideColumn(column.accessor);
	};

	const onChangePageSize = (size: number) => {
		setPageSize(size);
	};

	const onReload = () => {
		// NB: Called after an edit has taken place, this will cause the table to reload
		setManualReloadFlag(manualReloadFlag + 1);
	};

	const onRunCustomImprovements = () => {
		setCustomImprovementSetsModalOpen(true);
	};

	const onBulkCustomImprovementSetsStarted = (batches: Batch[]) => {
		setCurrentBulkCustomImprovementSetsBatches(batches);
	};

	const onDeleteProperties = () => {
		setConfirmDeleteModalOpen(true);
	};

	const onConfirmDeleteProperties = () => {

		setIsDeleting(true);

		client
			.deleteTeamProperties(team.id, selectedPropertyIds)
			.then(() => {

				setIsDeleting(false);
				setConfirmDeleteModalOpen(false);
				onReload();

			});

	};

	const onExport = () => {
		setExportFromTableModalOpen(true);
	};

	const onCloseExportModal = () => {
		setExportFromTableModalOpen(false);
	};

	const onSelectedGroupChange = (groups: Groups[]) => {
		setSelectedGroups(groups);
		const groupIds = groups.map((group: Group) => group.id);
		setSearch((params) => {
			params.set("group", groupIds);
			return params;
		});
	};

	const selectedProperties = selectedFlatRows.map((obj) => obj.original);
	const consolidatedFilters = getConsolidatedFilters(state.filters);

	return (
		<div>
			<ColumnGroupCheckboxes onGroupVisibilityChanged={(params: { columns: ColumnDefinition[] }) => onGroupVisibilityChanged(params)} />
			<div className="datatable-group-buttons">
				<Button
					variant="contained"
					onClick={onExport}
				>
					Export
				</Button>
				<GroupSelect
					multiple
					onChange={onSelectedGroupChange}
					placeholder="Groups"
					className="group-selector"
					currentlySelected={groupIdParams}
				/>
				<GroupButtons
					selectedProperties={selectedProperties}
					showRemove
					// NB: Ideally use the group, but this is not fetched when it is passed by param so default to just ID
					groups={selectedGroups ?? groupIdParams}
					filters={consolidatedFilters}
					onUpdate={onReload}
				/>
				<Button 
					variant="contained" 
					disabled={selectedFlatRows.length === 0}
					onClick={onRunCustomImprovements}
				>
					Run Custom Measures
				</Button>
				<Button
					variant="contained"
					disabled={selectedFlatRows.length === 0}
					onClick={onDeleteProperties}
				>
					Delete Properties
				</Button>
			</div>
			<div ref={dummyScrollRef}>
				<div className="dummy-scrollbar">
					<div className="dummy-scrollbar-inner-width"></div>
				</div>
				<div className="datatable-container ">
					{/* <ScrollContainer> */}
					<table {...getTableProps()} className="overflow-x-scroll">
						<thead className="datatable-column-thead">
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => {
										return (
											<th
												className="datatable-column-th"
												// @ts-ignore
												{...column.getHeaderProps(column.getSortByToggleProps())}
											>
												<div className="column-header">
													<span>{column.render("Header")}</span>
													{/* @ts-ignore */}
													{editable && column.canEdit && (
														<BulkEditButton
															// @ts-ignore
															modal={column.bulkEditModal}
															selectedPropertyIds={selectedPropertyIds}
															onReload={() => onReload()}
														/>
													)}
												</div>
												<div className="column-sort-order">
													{/* @ts-ignore */}
													{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
												</div>
												<div className="column-filter">
													{/* TODO: Why though? This definitely exists */}
													{/* @ts-ignore */}
													{column.canFilter ? column.render("Filter") : null}
												</div>
											</th>
										);
									})}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr className="datatable-column-tr" {...row.getRowProps()}>
										{row.cells.map((cell, index: number) => {
											return <Cell key={index} reactTableCell={cell} displayIntegrityTiles={displayIntegrityTiles} />;
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					{/* </ScrollContainer> */}
					{loading && <Preloader cover />}
					{error && <Message>{error}</Message>}
				</div>
			</div>
			<Button
				variant="contained"
				onClick={onExport}
			>
				Export
			</Button>
			<Pagination
				canPreviousPage={pageIndex > 0}
				canNextPage={pageIndex < pageCount - 1}
				pageSize={page.length}
				numResults={totalEntries}
				numPages={pageCount}
				currentPageIndex={pageIndex}
				onChangePageSize={(size: number) => onChangePageSize(size)}
				onPreviousPage={() => previousPage()}
				onNextPage={() => nextPage()}
				onFirstPage={() => gotoPage(0)}
				onLastPage={() => gotoPage(pageCount - 1)}
				onJumpToPage={(number: number) => gotoPage(number - 1)}
			/>

			{
				customImprovementSetsModalOpen && 
				<CustomImprovementSetsModal
					teamId={team.id}
					data={{
						properties: selectedPropertyIds
					}}
					onClose={() => setCustomImprovementSetsModalOpen(false)}
					onComplete={(batches: Batch[]) => onBulkCustomImprovementSetsStarted(batches)}
				/>
			}
			{
				currentBulkCustomImprovementSetsBatches &&
				<BulkCustomImprovementSetsProgressModal
					batches={currentBulkCustomImprovementSetsBatches}
					onClose={() => setCurrentBulkCustomImprovementSetsBatches(undefined)}
				/>
			}
			{
				<ConfirmationModal
					title="Delete Properties"
					open={confirmDeleteModalOpen}
					buttonsDisabled={isDeleting}
					onCancel={() => setConfirmDeleteModalOpen(false)}
					onConfirm={onConfirmDeleteProperties}
				>
					<p>
						Are you sure you want to delete the selected {selectedPropertyIds.length} properties?
					</p>
					<p>
						<strong>This action cannot be undone.</strong>
					</p>
				</ConfirmationModal>
			}
			{
				exportFromTableModalOpen && (
					<ExportFromTableModal
						selectedPropertyIds={selectedPropertyIds}
						groupId={groupIdParams}
						onClose={onCloseExportModal}
					/>
				)
			}
		</div>
	);
}
