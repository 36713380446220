export default function StorageHeatersVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1305"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1305)">
				<line x1="6" y1="17" x2="6" y2="20" stroke="#1C1B1F" strokeWidth="2" />
				<line x1="18" y1="17" x2="18" y2="20" stroke="#1C1B1F" strokeWidth="2" />
				<rect x="4" y="5" width="4" height="12" rx="1" stroke="#1C1B1F" strokeWidth="2" />
				<rect x="8" y="5" width="4" height="12" stroke="#1C1B1F" strokeWidth="2" />
				<rect x="12" y="5" width="4" height="12" stroke="#1C1B1F" strokeWidth="2" />
				<rect x="16" y="5" width="4" height="12" rx="1" stroke="#1C1B1F" strokeWidth="2" />
			</g>
		</svg>
	);
}
