export default function CompleteVector() {
	return (
		<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.46686 9.00007L0.700195 5.2334L1.6502 4.30007L5.41686 8.06673L4.46686 9.00007ZM8.23353 9.00007L4.46686 5.2334L5.4002 4.2834L8.23353 7.11673L14.3669 0.983398L15.3002 1.9334L8.23353 9.00007ZM8.23353 5.2334L7.28353 4.30007L10.5835 1.00007L11.5335 1.9334L8.23353 5.2334Z"
				fill="#30613C"
			/>
		</svg>
	);
}
