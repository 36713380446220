/* mears-mcmview-override-contents

import { User } from "../types/types";
import Role from "../rest/Role";

export function useUserContext(): User
{
	return {
		id: 0,
		team_id: 0,
		is_admin: false,
		email: "",
		role: Role.USER,
		picture: "",
		nickname: ""
	};
}

*/

import { User } from "../types/types";
import Role from "../rest/Role";
import { createContext, useContext } from "react";

const UserContext = createContext<User | null>({
	id: 0,
	team_id: 0,
	is_admin: false,
	email: "",
	role: Role.USER,
	picture: "",
	nickname: ""
});

export default UserContext;

export function useUserContext() {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("No UserContext.Provider found when calling useUserContext.");
	}
	return context;
}
