import { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";

import { useTeamContext } from "../../contexts/TeamContext";
import { useRestClientContext } from "../../contexts/RestClientContext";

import GroupCard from "../UI/GroupCard/GroupCard";
import Preloader from "../UI/Preloader/Preloader";

import "./GroupCards.scss";
import { Group } from "../../types/types";

export default function GroupCards() {
	// const options = [{ key: "1", text: "Date Created", value: "1" }];

	const client = useRestClientContext();
	const team = useTeamContext();

	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState<Group[]>([]);
	const [numGroupsDeleted, setNumGroupsDeleted] = useState(0);

	useEffect(() => {

		setLoading(true);

		client
			.fetchTeamGroups(team.id)
			.then((response) => response.json())
			.then((json) => {
				setGroups(json);
				setLoading(false);
			});
		
	}, [client, team.id]);

	const onGroupDeleted = () => {
		setNumGroupsDeleted(numGroupsDeleted + 1);
	};

	return (
        <div className="group-page-container">
            {/* <div className="group-page-container-options">
                <Select options={options} size="md" label="Sort By:"></Select>
            </div> */}
            <div className="groups-outer-container">
                <Grid stackable columns={2}>
                    {groups.map((group) => (
                        <Grid.Column key={group.id} width={6}>
                            <GroupCard group={group} onDeleted={onGroupDeleted} />
                        </Grid.Column>
                    ))}
                </Grid>
                {loading && <Preloader status="Loading groups..." />}
            </div>
        </div>
	);
}
