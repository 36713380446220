import React from "react";

import { XSquare } from "react-feather";

import CompleteVector from "../../../assets/CompleteVector";
import ErrorVector from "../../../assets/ErrorVector";
import ProgressVector from "../../../assets/ProgressVector";
import { SmartTargetStatus } from "../../../types/types";
import ChipLabel from "../../UI/ChipLabel/ChipLabel";

interface SmartTargetStatusChipProps {
	type: SmartTargetStatus;
}

export default function SmartTargetStatusChip({ type }: SmartTargetStatusChipProps) {
	return type === SmartTargetStatus.FINISHED ? (
		<ChipLabel label="Complete" icon={<CompleteVector />} variant="positive" />
	) : type === SmartTargetStatus.RUNNING || type === SmartTargetStatus.PENDING ? (
		<ChipLabel label="Running" icon={<ProgressVector />} variant="neutral" />
	) : type === SmartTargetStatus.ERROR ? (
		<ChipLabel label="Error" icon={<ErrorVector />} variant="negative" />
	) : type === SmartTargetStatus.CANCELLED ? (
		<ChipLabel label={"Cancelled"} icon={<XSquare />}  variant="negative"/>
	) : (
		<></>
	);
}
