/* mears-mcmview-override-contents

import PieChart from "../../../shims/NetZeroGoalPieChart";

export default PieChart;

*/


import React from "react";
import PieChart from "./PieChart";

import { NetZeroGoalData } from "./NetZeroGoal";
import { DefaultRawDatum } from "@nivo/pie";

interface NetZeroGoalChartProps
{
	data?: NetZeroGoalData;
}

export interface NetZeroGoalDatum extends DefaultRawDatum
{
	label: string;
	color: string;
}

const NetZeroGoalChart = ({ data }: NetZeroGoalChartProps) => {

	const pieChartData: NetZeroGoalDatum[] = [
		{
			id: "Current Position",
			label: "Current Position",
			value: data!.current_position,
			color: "green",
		},
		{
			id: `${data?.target_year} Target`,
			label: `${data?.target_year} Target`,
			value: data!.target_position,
			color: "red",
		},
		{
			id: "Target",
			label: "Target",
			value: 20,
			color: "hsl(160, 70%, 50%)",
		},
	];

	return (
		<PieChart
			percentage={data?.current_position}
			data={pieChartData}
		></PieChart>
	);
};

export default NetZeroGoalChart;
