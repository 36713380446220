import { PropsWithChildren } from "react";
import { Divider, Grid, Message, MessageContent, MessageHeader } from "semantic-ui-react";

import Property, { propertyUnits } from "../../rest/Property";

import Card from "../UI/Card/Card";
import Field from "./Field";

import "./DetailsCard.scss";

interface DetailsCardProps extends PropsWithChildren {
	property: Property;
}

const propertyColumns = [
	"wall_type",
	"wall_construction",
	"roof_type",
	"roof_construction",
	"glazing_type",
	"glazing_u_value",
	"fuel_type",
	"main_fuel_price",
	"lighting",
];

export default function DetailsCard({ children, property }: DetailsCardProps) {
	const formatPvString = (property: Property) => {
		let pvString = property.data.pvpower ? `${property.data.pvpower}kW h` : 'None';
		if (property.pvOrientation != null) {
			pvString += ` (${property.pvOrientation})`;
		}
		return pvString;
	}

	const formatColumnValue = (column: string, default_?: string): string =>
		property.data[column] ? property.data[column] + (propertyUnits[column] ?? "") : default_ ?? "";

	const formatMultipleColumnValues = (columns: string[], split?: "space" | "bracket"): string => {
		const colMerge = columns
			.reduce((acc, curr) => {
				if (split === "bracket" && acc.trim() !== "") {
					return `${acc} (${formatColumnValue(curr)})`;
				}
				return `${acc} ${formatColumnValue(curr)}`;
			}, "")
			.trim();

		if (colMerge === "") {
			return "Not available";
		}
		return colMerge;
	};

	return (
		<Card title="Property Details" className="property-details">
			{property.isPropertyDataNull(propertyColumns) ? (
				<Message>
					<MessageHeader>No data</MessageHeader>
					<MessageContent>Could not find any data for this property</MessageContent>
				</Message>
			) : (
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Field label="UPRN" value={property.data.uprn} />
							<Field label="Address" value={property.fullAddress} />
							<Field
								label="Wall Construction"
								value={formatMultipleColumnValues(["wall_type", "wall_construction"])}
							/>
							<Field
								label="Roof Insulation"
								value={formatMultipleColumnValues(["roof_type", "roof_construction"], "bracket")}
							/>
							<Field
								label="Glazing"
								value={formatMultipleColumnValues(["glazing_type", "glazing_u_value"], "bracket")}
							/>
							<Field
								label="Fuel Type"
								value={formatMultipleColumnValues(["fuel_type", "main_fuel_price"], "bracket")}
							/>
							<Field
								label="Boiler"
								value={`${property.boiler} (${property.data.heating_efficiency})`}
							/>
							<Field label="PV" value={formatPvString(property)} />
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column>
							<Field label="Lighting" value={formatColumnValue("lighting", "Not available")} />
							<Field label="Current Heat Demand" value={property.currentHeatDemand} />
							<Field label="Potential Heat Demand" value={property.potentialHeatDemand} />
							<Field label="Current CO2 Usage" value={property.currentCo2Usage} />
							<Field label="Potential CO2 Usage" value={property.potentialCo2Usage} />
							<Field label="ODC EPC Lodgement Date" value={property.odcEpcLodgementDate} />
							<Field
								label="Last accepted ODC EPC suggestion"
								value={property.odcLastAcceptedSuggestion}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}

			<Divider vertical className="details-divide" />

			<div className="epc-ratings">
				<div className="rating current">
					<span className="label">Current</span>
					<span className="value">{property.data.epc_rating ?? "N/A"}</span>
				</div>
				<div className="rating potential">
					<span className="label">Potential</span>
					<span className="value">{property.potentialEpcRating ?? "N/A"}</span>
				</div>

				{children}
			</div>
		</Card>
	);
}
