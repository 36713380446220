import { Header } from "semantic-ui-react";

import Page from "./Page";
import "./Dashboard.scss";

import DashboardGrid from "../components/Dashboard/DashboardGrid";

export default function Dashboard() {
	return (
		<Page>
			<div className="header-text-container">
				<Header as="h1">Dashboard</Header>
				<p>Welcome to your new DREam dashboard</p>
			</div>
			<DashboardGrid/>
		</Page>
	);
}
