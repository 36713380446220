/* mears-mcmview-override-contents

import OptimisationChart from "../../shims/OptimisationChart";

export default OptimisationChart;

*/


import { DefaultRawDatum, ResponsivePie, MayHaveLabel } from "@nivo/pie";

interface OptimisationChartProps {
	data: DefaultRawDatum[]
}

export default function OptimisationChart({ data }: OptimisationChartProps) {
	return (
		<ResponsivePie
			data={data as MayHaveLabel[]}
			margin={{
				left: -80,
				bottom: 20,
			}}
			innerRadius={0.8}
			padAngle={0}
			startAngle={-90}
			endAngle={90}
			cornerRadius={3}
			borderWidth={1}
			colors={{
				scheme: "category10",
			}}
			borderColor={{
				from: "color",
				modifiers: [["darker", 0.2]],
			}}
			animate={true}
			// TODO: Review please, TS says no such properties
			// motionStiffness={90}
			// motionDamping={15}
			defs={[]}
			// enableRadialLabels={false}
			// enableSlicesLabels={false}
			enableArcLabels={false}
			legends={[
				{
					anchor: "right",
					direction: "column",
					itemWidth: 100,
					itemHeight: 60,
					itemTextColor: "#999",
					symbolSize: 18,
					symbolShape: "circle",
					effects: [
						{
							on: "hover",
							style: {
								itemTextColor: "#000",
							},
						},
					],
				},
			]}
		/>
	);
}
