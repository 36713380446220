/* mears-mcmview-override-contents
export default function DeletePropertyButton(_: any) {
	return <></>
}
 */

import Button from "../UI/Button/Button";

interface DeletePropertyButtonProps {
	onDelete: () => void;
}

export default function DeletePropertyButton({ onDelete }: DeletePropertyButtonProps) {
	return (
		<Button variant="contained" onClick={onDelete}>
			Delete Property
		</Button>
	);
}
