import { DateTime } from "luxon";
import { CustomImprovementsSet } from "../types/types";

export interface PropertyImage {
	additional_comments: string;
	affected_areas: string;
	analysis: string;
	id: number;
	insulation_performance: string;
	property_id: number;
	result: string;
	url: string;
}

// TODO: I don't think it's ideal to be using any here, is there any way around that?
export interface PropertyResponse extends Record<string, any> {
	id: number;
	addr_no: string;
	addr_street: string;
	addr_city: string;
	postcode: string;
	groups: string;
	lat: number;
	lng: number;
	epc_rating: number;
	epc_rating_grade: string;
	images?: PropertyImage[];
	custom_improvement_sets: CustomImprovementsSet[];
	bulk_custom_improvements_update_batch_uuid?: string;
	heating_efficiency?: number;
	sap_data?: any;
}

export const propertyUnits: Record<string, string> = {
	roof_type: "mm",
	lighting: "%",
}

const colNotAvailable = "Not available";

export default class Property {
	data: PropertyResponse;

	constructor(data: PropertyResponse) {
		// NB: Really should protect this but it's still here for legacy support (code which accesses data directly)
		this.data = data;

		for (const key in data) {
			let value = data[key];

			switch (key) {
				case "groups":
					if (typeof value === "string" || value instanceof String) {
						if (!value.match(/^\d+(,\d+)*$/)) throw new Error("Invalid groups value");

						value = value.split(",").map((id) => parseInt(id));
					}

					break;

				default:
					// NB: Do nothing, no special handling for this value
					break;
			}

			Object.defineProperty(this, key, {
				value: value,
				writable: false,
			});
		}
	}

	/**
	 * Finds if the property predominately contains null data.
	 * @param columns (optional) Array of column names to check for data. Defaults to a selection of commonly filled
	 * columns. Returns true if all of these columns are null/undefined.
	 */
	isPropertyDataNull(columns: string[] = []) {
		if (columns.length) {
			let nullCols = 0;
			for (const column of columns) {
				// support 1 level nesting (mainly for sap data)
				const splitCol = column.split(".");

				nullCols += Number(
					(splitCol.length === 2 && this.data[splitCol[0]][splitCol[1]] == null) ||
						(splitCol.length === 1 && this.data[column] == null)
				);
			}
			return nullCols === columns.length;
		}
		// Test on some key columns that do not default to a value
		return (
			!this.data.epc_rating &&
			!this.data.lat &&
			!this.data.lng &&
			this.fullAddress === colNotAvailable &&
			this.data.custom_improvement_sets.length === 0
		);
	}

	get id() {
		return this.data.id;
	}

	get teamId() {
		return this.data.team_id;
	}

	get googleLatLng() {
		if (!this.data.lat && !this.data.lng) {
			return null;
		}
		return {
			lat: this.data.lat,
			lng: this.data.lng,
		};
	}

	get fullAddress() {
		if (!this.data.addr_no && !this.data.addr_street && !this.data.addr_city && !this.data.postcode) {
			return colNotAvailable;
		}
		return `${this.data.addr_no} ${this.data.addr_street}, ${this.data.addr_city}, ${this.data.postcode}`;
	}

	get currentHeatDemand() {
		this.assertSapDataPresent();
		return this.data.sap_data.current_heat_demand ? this.data.sap_data.current_heat_demand + " kWh/m2" : colNotAvailable;
	}

	get potentialHeatDemand() {
		this.assertSapDataPresent();
		return this.data.sap_data.potential_heat_demand? this.data.sap_data.potential_heat_demand + " kWh/m2" : colNotAvailable;
	}

	get currentCo2Usage() {
		this.assertSapDataPresent();

		// TODO: Have a look at this please, why on earth would there be a backspace in this data?! Should be safe to rely on the v2 server to always cast this to float and just round it
		return this.data.sap_data.current_co2_use
			? `${this.data.sap_data.current_co2_use.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}Kg`
			: colNotAvailable;
	}

	get potentialCo2Usage() {
		this.assertSapDataPresent();
		// TODO: Have a look at this please, why on earth would there be a backspace in this data?! Should be safe to rely on the v2 server to always cast this to float and just round it
		return this.data.sap_data.potential_co2
			? `${this.data.sap_data.potential_co2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}Kg`
			: colNotAvailable;
	}

	get odcEpcLodgementDate() {
		return this.data.odc_lodgement_date
			? DateTime.fromSQL(this.data.odc_lodgement_date).toFormat("MMMM dd, yyyy")
			: "Never fetched";
	}

	get odcLastAcceptedSuggestion() {
		return this.data.odc_last_accepted_suggestion
			? DateTime.fromSQL(this.data.odc_last_accepted_suggestion).toFormat("MMMM dd, yyyy")
			: "Never";
	}

	get potentialEpcRating() {
		this.assertSapDataPresent();
		return this.data.sap_data.improved_to_cbr_sap_value ?? colNotAvailable;
	}

	get boiler() {
		return this.data.boiler_type ?? this.data.heating_type ?? colNotAvailable;
	}

	get sapData() {
		return this.data.sap_data;
	}

	get pvOrientation() {
		const orientations = ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "Flat roof"];
		if (this.data.solarpvorientation)
			return orientations[this.data.solarpvorientation];
		return null;
	}

	assertSapDataPresent() {
		if (!("sap_data" in this.data))
			throw new Error(
				"SAP Data not loaded for property. Please request SAP data from REST API server to use this property."
			);
	}
}
