/* mears-mcmview-override-contents

import EpcPieChart from "../../../../shims/EpcPieChart";

export default EpcPieChart;

*/


// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from "@nivo/pie";

import { KeyValuePair } from "../../../../types/types";

import "./EpcPieChart.scss";

interface EpcPieChartProps
{
	data: KeyValuePair;
}

const EpcPieChart = ({ data }: EpcPieChartProps) => {
	// TODO: Put these somewhere central please
	const colorsByGrade: KeyValuePair = {
		A: "#43936c",
		B: "#6acf9d",
		C: "#8de3ba",
		D: "#f2c04b",
		E: "#ebaf64",
		F: "#43936c",
		G: "#ca3a31",
	};

	const translated = ["A", "B", "C", "D", "E", "F", "G"].map((grade) => {
		return {
			id: grade,
			label: grade,
			value: data["total_grade_" + grade.toLowerCase()],
			color: colorsByGrade[grade],
		};
	});

	return (
		<div className="epc-pie-chart-container">
			<ResponsivePie
				data={translated}
				sortByValue={true}
				innerRadius={0.8}
				padAngle={1}
				cornerRadius={3}
				activeInnerRadiusOffset={1}
				activeOuterRadiusOffset={20}
				borderWidth={1}
				isInteractive={false}
				animate={false}
				colors={{ datum: "data.color" }}
				borderColor={{
					from: "color",
					modifiers: [["darker", 0.2]],
				}}
				enableArcLinkLabels={false}
				arcLinkLabelsSkipAngle={10}
				arcLinkLabelsTextColor="#333333"
				arcLinkLabelsThickness={2}
				arcLinkLabelsColor={{ from: "color" }}
				enableArcLabels={false}
				arcLabelsSkipAngle={10}
				layers={["arcs", "arcLabels"]}
				arcLabelsTextColor={{
					from: "color",
				}}
			/>
			<div className="epc-pie-chart-overlay">
				<span className="property-counter">{data.total_properties?.toLocaleString()}</span>
				<span className="epc-pie-chart-total-properties-label">Properties</span>
			</div>
		</div>
	);
};

export default EpcPieChart;
