import React, { useEffect, useState } from "react";
import { Grid, Message } from "semantic-ui-react";
import Card from "../../UI/Card/Card";
import NetZeroGoalChart from "./NetZeroGoalChart";
import NetZeroGoalFigures from "./NetZeroGoalFigures";
import Preloader from "../../UI/Preloader/Preloader";
import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useTeamContext } from "../../../contexts/TeamContext";

export interface NetZeroGoalData {
	current_position: number;
	target_position: number;
	target_year: number;
}

const NetZeroGoal = () => {
	const team = useTeamContext();
	const client = useRestClientContext();

	const [netZeroGoalData, setNetZeroGoalData] = useState<NetZeroGoalData>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		setLoading(true);
		setError("");

		client
			.fetchNetZeroGoal(team.id)
			.then((response: Response) => response.json())
			.then((json: NetZeroGoalData) => {
				setLoading(false);
				setNetZeroGoalData(json);
			})
			.catch((_) => {
				setLoading(false);
				setError("Error fetching net zero data");
			})
	}, [client, team.id]);

	return (
		<Card title="Net Zero Goal" minHeight={357}>
			{loading ? (
				<Preloader />
			) : error ? (
				<Message error>{error}</Message>
			) : netZeroGoalData ? (
				<Grid columns={2} padded="vertically" stackable>
					<Grid.Column width={9}>
						<NetZeroGoalChart data={netZeroGoalData} />
					</Grid.Column>
					<Grid.Column width={7}>
						<NetZeroGoalFigures data={netZeroGoalData} />
					</Grid.Column>
				</Grid>
			) : (
				<Message>No data</Message>
			)}
		</Card>
	);
};

export default NetZeroGoal;
