/* mears-mcmview-override-contents

import { useState, useCallback } from 'react';

export default function useSearchParams() {
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));

  const setSearch = useCallback((updateFunction) => {
    const newParams = new URLSearchParams(searchParams);
    updateFunction(newParams);
    setSearchParams(newParams);
  }, [searchParams]);

  return [searchParams, setSearch];
};

*/

import { useSearchParams as useReactRouterDomSearchParams } from "react-router-dom";

export default useReactRouterDomSearchParams;