import { ResponsiveBar } from "@nivo/bar";

import Card from "../UI/Card/Card";
import Property from "../../rest/Property";
import { Message, MessageContent, MessageHeader } from "semantic-ui-react";

interface EnergyUsageCardProps {
	property: Property;
}

export default function EnergyUsageCard({ property }: EnergyUsageCardProps) {
	const sap = property.data.sap_data;

	const data = [
		{
			label: "Current",
			"Hot Water": sap.current_hot_water_cost,
			Heating: sap.current_heating_cost,
			Lighting: sap.current_lighting_cost,
		},
		{
			label: "Potential",
			"Hot Water": sap.potential_hot_water_cost,
			Heating: sap.potential_heating_cost,
			Lighting: sap.potential_lighting_cost,
		},
	];
	const isDataNull = data.every((i) => Object.values({ ...i, label: null }).every((j) => j == null));

	return (
		<Card title="Energy Usage">
			{isDataNull ? (
				<Message>
					<MessageHeader>No data available</MessageHeader>
					<MessageContent>Update the property to see energy usage data here</MessageContent>
				</Message>
				) : (
				<div className="chart-container">
					<ResponsiveBar
						data={data}
						groupMode={"grouped"}
						keys={["Hot Water", "Lighting", "Heating"]}
						indexBy="label"
						margin={{ top: 50, right: 0, bottom: 50, left: 45 }}
						padding={0.15}
						// TODO: Can these be sourced from SCSS?
						colors={["#FF5F43", "#78D59F", "#F6E084"]}
						borderWidth={1}
						borderRadius={4}
						borderColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
					axisLeft={{ legend: "£ / annum", legendPosition: "middle", legendOffset: -40 }}
					/>
				</div>
			)
			}
		</Card>
	);
}
