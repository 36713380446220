export default function HeatingControlsVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1300"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1300)">
				<path
					d="M12 18C13.6667 18 15.0833 17.4167 16.25 16.25C17.4167 15.0833 18 13.6667 18 12C18 10.3333 17.4167 8.91667 16.25 7.75C15.0833 6.58333 13.6667 6 12 6C10.3333 6 8.91667 6.58333 7.75 7.75C6.58333 8.91667 6 10.3333 6 12C6 13.6667 6.58333 15.0833 7.75 16.25C8.91667 17.4167 10.3333 18 12 18ZM12 16C10.9 16 9.95833 15.6083 9.175 14.825C8.39167 14.0417 8 13.1 8 12C8 10.9 8.39167 9.95833 9.175 9.175C9.95833 8.39167 10.9 8 12 8C13.1 8 14.0417 8.39167 14.825 9.175C15.6083 9.95833 16 10.9 16 12C16 13.1 15.6083 14.0417 14.825 14.825C14.0417 15.6083 13.1 16 12 16ZM11.3 12.7C11.4833 12.8833 11.7167 12.975 12 12.975C12.2833 12.975 12.5167 12.8833 12.7 12.7L14.1 11.3C14.2833 11.1167 14.375 10.8833 14.375 10.6C14.375 10.3167 14.2833 10.0833 14.1 9.9C13.9167 9.71667 13.6833 9.625 13.4 9.625C13.1167 9.625 12.8833 9.71667 12.7 9.9L11.3 11.3C11.1167 11.4833 11.025 11.7167 11.025 12C11.025 12.2833 11.1167 12.5167 11.3 12.7ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
