import { useState } from "react";
import { Icon, Message, Form } from "semantic-ui-react";

import { useTeamContext } from "../../contexts/TeamContext";
import { useRestClientContext } from "../../contexts/RestClientContext";

import Button from "../UI/Button/Button";
import Modal from "../Modal/Modal";
import CsvFileInput from "../UI/CsvFileInput/CsvFileInput";
import Input from "../UI/Input/Input";
import Select from "../UI/Select/Select";

export default function GroupUpload() {
	// const options = [{ key: "1", text: "Date Created", value: "1" }];

	// NB: Real code starts here
	const client = useRestClientContext();
	const team = useTeamContext();

	const [createFromSpreadsheetOpen, setCreateFromSpreadsheetOpen] = useState(false);
	const [file, setFile] = useState<File[]>([]);
	const [headers, setHeaders] = useState<string[]>([]);
	const [uprnFieldIndex, setUprnFieldIndex] = useState("");
	const [groupName, setGroupName] = useState("");
	const [error, setError] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [warnings, setWarnings] = useState([]);

	var reader = new FileReader();

	const onUpload = () => {
		const formData = new FormData();
		formData.append("file", file[0]);
		formData.append("uprn_column_index", uprnFieldIndex);
		formData.append("group_name", groupName);

		setError(null);
		setUploading(true);

		client
			.createGroupFromUprnSpreadsheetUpload(team.id, formData)
			.then((response) => response.json())
			.then((json) => {
				setWarnings(() => json.warnings);
				if (json.error) throw new Error(json.error.message);
				if (json.warnings.length === 0 && !json.error) {
					setCreateFromSpreadsheetOpen(() => false);
					setFile([]);
					setHeaders([]);
					setUprnFieldIndex("");
					setGroupName("");
				}
			})
			.catch((error) => {
				setError(error.message);
			})
			.finally(() => setUploading(false));
	};

	reader.onloadend = function (evt) {
		const data = evt.target?.result as ArrayBuffer;
		const byteLength = data?.byteLength;

		// Turn it into an array of 8-bit ints aka chars
		const ui8a = new Uint8Array(data, 0);

		let headerString = "";

		for (let i = 0; i < byteLength; i++) {
			const char = String.fromCharCode(ui8a[i]);

			if (char.match(/[^\r\n]+/g) !== null) {
				headerString += char;
			} else {
				//found newline, set headers
				setHeaders(headerString.split(","));
				break;
			}
		}
	};

	const onFileChanged = (file: File[]) => {
		setFile(() => file);
		setError(null);
		setWarnings([]);
		reader.readAsArrayBuffer(file[0]);
	};

	return (
		<>
			<Button variant="contained" onClick={() => setCreateFromSpreadsheetOpen(true)}>
				<Icon name="add" />
				Create from spreadsheet
			</Button>

			<Modal
				title="Upload spreadsheet"
				open={createFromSpreadsheetOpen}
				closeIcon
				onClose={() => setCreateFromSpreadsheetOpen(false)}
			>
				<Form>
					<Form.Field>
						<label>Group Name</label>
						<Input value={groupName} onChange={(event) => setGroupName(() => event.target.value)} />
					</Form.Field>
					<CsvFileInput onChange={(file: File[]) => onFileChanged(file)} />
					{error && (
						<Message negative>
							<Message.Header>Error uploading file</Message.Header>
							<p>{error}</p>
						</Message>
					)}
					{warnings.length > 0 &&
						warnings.map((message) => (
							<Message warning>
								<p>{message}</p>
							</Message>
						))}

					{headers.length > 0 && (
						<Form.Field>
							<label>UPRN field</label>
							<Select
								options={headers.map((header, index) => {
									return { key: header, text: header, value: index };
								})}
								placeholder="Select column to be treated as the UPRN"
								value={uprnFieldIndex}
								onChange={(event, data) => setUprnFieldIndex(() => data.value as string)}
							/>
						</Form.Field>
					)}
					<Button variant="contained" disabled={uprnFieldIndex.length < 1} onClick={() => onUpload()}>
						<Icon name="upload" />
						{uploading ? "Please wait..." : "Upload"}
					</Button>
				</Form>
			</Modal>
		</>
	);
}
