/* mears-mcmview-override-contents

import queryString from "query-string";

export default function useLocation()
{
  const parsed = queryString.parse(location.search) as any;

  for (const key of ["filters", "sortBy"])
  {
    if (key in parsed)
      parsed[key] = JSON.parse(parsed[key]);
  }

  return {
    state: parsed
  };
}

*/

import { useLocation as useReactRouterDomLocation } from "react-router-dom";

export default useReactRouterDomLocation;