import { useEffect, useState } from "react";
import { Icon, Message } from "semantic-ui-react";
import CopyToClipboard from "react-copy-to-clipboard";

import { useRestClientContext } from "../../contexts/RestClientContext";

import Card from "../UI/Card/Card";
import Button from "../UI/Button/Button";
import Property from "../../rest/Property";

import "./AdminToolsCard.scss";

interface AdminToolsCardProps {
	property: Property;
}

export default function AdminToolsCard({ property }: AdminToolsCardProps) {
	const client = useRestClientContext();

	const [copied, setCopied] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [isSapUpdateRunning, setIsSapUpdateRunning] = useState(false);
	const [sapUpdateResponse, setSapUpdateResponse] = useState<string | null>(null);

	useEffect(() => {
		if (!copied) return;
		const timer = setTimeout(() => {
			setCopied(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [copied]);

	const onReRunSap = () => {
		setError(null);
		setIsSapUpdateRunning(true);
		setSapUpdateResponse(null);

		client
			.updatePropertySapData(property.data.team_id, property.data.id)
			.then((response: Response) => response.json())
			.then((json: any) => {
				if (json.error) throw json.error;

				setSapUpdateResponse(JSON.stringify(json, null, 2));
			})
			.catch((error: Error) => {
				setError(error.message);
			})
			.finally(() => {
				setIsSapUpdateRunning(false);
			});
	};

	return (
		<Card title="Admin Tools">
			<div className="inline-buttons">
				<CopyToClipboard text={JSON.stringify(property, null, 2)} onCopy={() => setCopied(true)}>
					<Button variant="contained" disabled={isSapUpdateRunning}>
						<Icon name={copied ? "check" : "copy outline"} />
						Copy JSON data
					</Button>
				</CopyToClipboard>
				<Button variant="outlined" onClick={() => onReRunSap()}>
					<Icon name={isSapUpdateRunning ? "hourglass half" : "calculator"} />
					Re-Run SAP
				</Button>
			</div>
			{error && (
				<Message negative>
					<Message.Header>Error</Message.Header>
					<p>{error}</p>
				</Message>
			)}
			{sapUpdateResponse && (
				<Message success>
					<Message.Header>SAP Data Updated</Message.Header>
					<p>
						Recalculation was successful. &nbsp;{" "}
						{/* NB: Some kind of madness going on with whitespace being ignored here */}
						<CopyToClipboard text={sapUpdateResponse}>
							<Button variant="tect">
								Click here to copy the response.
								<Icon name="copy outline" />
							</Button>
						</CopyToClipboard>
					</p>
				</Message>
			)}
		</Card>
	);
}
