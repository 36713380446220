export default function PerformanceGlazingVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1320"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1320)">
				<path d="M4 21V3H20V21H4ZM6 11H11V10H13V11H18V5H6V11ZM6 19H18V13H6V19Z" fill="#1C1B1F" />
			</g>
		</svg>
	);
}
