import { useState } from "react";
import { DateTime } from "luxon";
import { Message, Segment, Table, Icon } from "semantic-ui-react";

import Preloader from "../UI/Preloader/Preloader";
import Checkbox from "../UI/Checkbox/Checkbox";
import Modal from "../Modal/Modal";

import useOdcEpcSuggestions, { OdcEpcSuggestion } from "../../hooks/useOdcEpcSuggestions";
import Button from "../UI/Button/Button";
import FriendlyNames from "../../database/FriendlyNames";

import "./EpcSuggestionsModal.scss";

import { useUserContext } from "../../contexts/UserContext";
import { useTeamContext } from "../../contexts/TeamContext";
import { useRestClientContext } from "../../contexts/RestClientContext";
import Property from "../../rest/Property";

const wantedSuggestions = [
	"uprn",
	"construction_age",
	"wall_construction",
	"roof_type",
	"glazing_type",
	"floor_area",
	"floor_construction",
	"floorconstruction",
	"room_height1",
	"room_height2",
	"room_height3",
	"lighting",
];



interface EpcSuggestionsModalProps {
	property: Property;
	onClose: Function;
}

export default function EpcSuggestionsModal({ property, onClose }: EpcSuggestionsModalProps) {
	const user = useUserContext();
	const team = useTeamContext();
	const client = useRestClientContext();

	const [pendingAcceptResponse, setPendingAcceptResponse] = useState(false);
	const [acceptedSuggestions, setAcceptedSuggestions] = useState<Record<string, OdcEpcSuggestion>>({});
	const [logVisible, setLogVisible] = useState(false);

	const { loading, warnings, suggestions, date, log } = useOdcEpcSuggestions({ property });

	const onToggleAccept = (suggestion: OdcEpcSuggestion, accept: boolean) => {
		const value: Record<string, OdcEpcSuggestion> = { ...acceptedSuggestions };

		if (!accept && suggestion.key in acceptedSuggestions) delete value[suggestion.key];

		if (accept) value[suggestion.key] = suggestion;

		setAcceptedSuggestions(value);
	};

	const onAccept = () => {
		const data: Record<string, string | number> = {};

		Object.values(acceptedSuggestions).forEach((obj) => {
			data[obj.key] = obj.suggested;
		});

		const odcLodgementDate = suggestions.filter((val) => val.key === "odc_lodgement_date");
		if (odcLodgementDate.length > 0) {
			data["odc_lodgement_date"] = odcLodgementDate[0].suggested;
		}

		data["odc_last_accepted_suggestion"] = DateTime.now().toFormat("yyyy-MM-dd");

		setPendingAcceptResponse(true);

		client
			.updateProperty(team.id, property.id, data)
			.then(() => {
				window.location.reload(); // NB: Reload the whole page for now, cheap way to reload the property
			});
	};

	return (
		<Modal title="Compare &amp; Update" className="epc-suggestions-modal" closeIcon onClose={() => onClose()}>
			{loading && <Preloader />}
			{!loading && (
				<>
					{user.is_admin &&
						warnings.map((message, index) => (
							<Message key={index} warning>
								{message}
							</Message>
						))}
					<p>
						<em>Last updated {date?.toFormat("MMMM dd, yyyy")}</em>
					</p>
					{!!suggestions.length ? (
						<Table celled className="odc-epc-suggested-values">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Field</Table.HeaderCell>
									<Table.HeaderCell>Current</Table.HeaderCell>
									<Table.HeaderCell>Suggested</Table.HeaderCell>
									<Table.HeaderCell>Accept</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{suggestions.map((suggestion) => {
									if (!wantedSuggestions.includes(suggestion.key)) return null;

									const accepted = suggestion.key in acceptedSuggestions;

									return (
										<Table.Row key={suggestion.key} positive={accepted}>
											<Table.Cell>{FriendlyNames.fromColumnName(suggestion.key)}</Table.Cell>
											<Table.Cell
												className={acceptedSuggestions[suggestion.key] && "will-be-replaced"}
											>
												{suggestion.current}
											</Table.Cell>
											<Table.Cell>
												{suggestion.suggested}
												{accepted && <Icon name="checkmark" />}
											</Table.Cell>
											<Table.Cell>
												<Checkbox
													checked={accepted}
													onClick={(event, data) => onToggleAccept(suggestion, data.checked ? true : false)}
												/>
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					) : (
						<Message info>
							<Message.Header>No Results</Message.Header>
							There are no suggested changes for this property
						</Message>
					)}

					{/* log will likely be empty - it currently does not get set on the back end */}
					{user.is_admin && !!log?.length && (
						<>
							<Button variant="text" onClick={() => setLogVisible(true)}>
								View log
							</Button>
							{logVisible && <pre>{log}</pre>}
						</>
					)}
					<Segment basic className="button-container">
						<Button variant="contained" disabled={pendingAcceptResponse} onClick={onAccept}>
							{acceptedSuggestions.length ? "Accept" : "Update"}
							{pendingAcceptResponse && (
								<>
									&nbsp;
									<Preloader variant="inline" />
								</>
							)}
						</Button>
					</Segment>
				</>
			)}
		</Modal>
	);
}
