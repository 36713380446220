import { useState } from "react";
import { Form, Message } from "semantic-ui-react";

import TeamSelect from "../UI/TeamSelect/TeamSelect";
import CsvFileInput from "../UI/CsvFileInput/CsvFileInput";
import Preloader from "../UI/Preloader/Preloader";

import { useUserContext } from "../../contexts/UserContext";
import { useRestClientContext } from "../../contexts/RestClientContext";
import Checkbox from "../UI/Checkbox/Checkbox";

interface LogEntry
{
	datetime: string;
	message: string;
}

interface ImportResponse
{
	error?: {message: string};
	log: LogEntry[];
	id?: string;
}

export default function ImportProperties({importMonitorPath}: {importMonitorPath: string}) {
	const client = useRestClientContext();
	const user = useUserContext();

	const [loading, setLoading] = useState(false);
	const [successful, setSuccessful] = useState(false);
	const [teamId, setTeamId] = useState(user.team_id);
	const [legacyMode, setLegacyMode] = useState(false);
	const [improvementSuggester, setImprovmentSuggester] = useState(false);
	const [files, setFiles] = useState<File[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [log, setLog] = useState<LogEntry[]>([]);

	const onSubmit = () => {
		const formData = new FormData();
		formData.append("file", files[0]);
		formData.append("legacyMode", String(legacyMode ? 1 : 0));
		formData.append("runRecommendedImprovements", String(improvementSuggester ? 1 : 0));
		formData.append("teamId", String(teamId)); // NB: Added for MCM, where this isn't part of the route

		setError(null);
		setLoading(true);
		setSuccessful(false);

		client
			.uploadImport(teamId, formData)
			.then((response: Response) => response.json())
			.then((json: ImportResponse) => {
				if (json.error) throw new Error(json.error.message);

				if (json.log) setLog(json.log);
				else {
					setLog([]);
					setSuccessful(true);

					window.open(`${importMonitorPath}/${json.id}`, "_BLANK");
				}
			})
			.catch((error: {message: string}) => {
				if ("message" in error) setError(error.message);
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<Form id="import">
				<Form.Field>
					<label>File</label>
					<CsvFileInput onChange={(files: File[]) => setFiles(files)} />
				</Form.Field>
				<Form.Field>
					<label>Team</label>
					<TeamSelect showWithZeroProperties onChange={(value: number) => setTeamId(value)} />
				</Form.Field>
				<Form.Field>
					<label>Run recommended imports</label>
					<Checkbox
						label={"Suggest improvements to the properties"}
						checked={improvementSuggester}
						onChange={() => setImprovmentSuggester(!improvementSuggester)}
					/>
				</Form.Field>
				<Form.Field>
					<label>Legacy mode</label>
					<Checkbox
						label={"Trust values in CSV data over values computed in internal lookups"}
						checked={legacyMode}
						onChange={() => setLegacyMode(!legacyMode)}
					/>
				</Form.Field>
				<Form.Button onClick={onSubmit}>Upload</Form.Button>
				{loading && <Preloader cover />}
			</Form>
			{successful && (
				<Message info>
					<Message.Header>Upload complete</Message.Header>
					<p>File received</p>
				</Message>
			)}
			{error && (
				<Message negative>
					<Message.Header>Error uploading file</Message.Header>
					<pre>{error}</pre>
				</Message>
			)}
			{log && (
				<>
					{" "}
					{log.map((entry) => (
						<Message warning>
							<Message.Header>{entry.datetime}</Message.Header>
							<p>{entry.message}</p>
						</Message>
					))}
				</>
			)}
		</>
	);
}
