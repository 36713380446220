import React from "react";

interface NextVectorProps
{
	fill?: string;
	stroke?: string;
}

const NextVector = ({ fill, stroke }: NextVectorProps) => {
	fill = fill ?? "none";
	stroke = stroke ?? "#ff5f43";

	return (
		<svg width="8" height="13" viewBox="0 0 8 13" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.07737 6.85684C0.877686 6.66085 0.877686 6.33915 1.07737 6.14316L6.14976 1.16463C6.46607 0.854177 7 1.07827 7 1.52147L7 11.4785C7 11.9217 6.46607 12.1458 6.14976 11.8354L1.07737 6.85684Z"
				stroke={stroke}
				transform="rotate(180, 4, 6.5)"
			/>
		</svg>
	);
};

export default NextVector;
