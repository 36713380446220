export default function LoftInsulationVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1335"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1335)">
				<path
					d="M9 5C7.9 5 6.95833 5.39167 6.175 6.175C5.39167 6.95833 5 7.9 5 9V15H19V9C19 7.9 18.6083 6.95833 17.825 6.175C17.0417 5.39167 16.1 5 15 5H9ZM9 21C7.33333 21 5.91667 20.4167 4.75 19.25C3.58333 18.0833 3 16.6667 3 15V9C3 7.33333 3.58333 5.91667 4.75 4.75C5.91667 3.58333 7.33333 3 9 3H15C16.6667 3 18.0833 3.58333 19.25 4.75C20.4167 5.91667 21 7.33333 21 9V15C21 16.6667 20.4167 18.0833 19.25 19.25C18.0833 20.4167 16.6667 21 15 21H9ZM9 19C9 18.7167 9.09583 18.4792 9.2875 18.2875C9.47917 18.0958 9.71667 18 10 18C10.2833 18 10.5208 18.0958 10.7125 18.2875C10.9042 18.4792 11 18.7167 11 19H13C13 18.7167 13.0958 18.4792 13.2875 18.2875C13.4792 18.0958 13.7167 18 14 18C14.2833 18 14.5208 18.0958 14.7125 18.2875C14.9042 18.4792 15 18.7167 15 19C15.75 19 16.425 18.8167 17.025 18.45C17.625 18.0833 18.1 17.6 18.45 17H17C17 17.2833 16.9042 17.5208 16.7125 17.7125C16.5208 17.9042 16.2833 18 16 18C15.7167 18 15.4792 17.9042 15.2875 17.7125C15.0958 17.5208 15 17.2833 15 17H13C13 17.2833 12.9042 17.5208 12.7125 17.7125C12.5208 17.9042 12.2833 18 12 18C11.7167 18 11.4792 17.9042 11.2875 17.7125C11.0958 17.5208 11 17.2833 11 17H9C9 17.2833 8.90417 17.5208 8.7125 17.7125C8.52083 17.9042 8.28333 18 8 18C7.71667 18 7.47917 17.9042 7.2875 17.7125C7.09583 17.5208 7 17.2833 7 17H5.55C5.9 17.6 6.375 18.0833 6.975 18.45C7.575 18.8167 8.25 19 9 19Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
