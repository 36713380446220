import { useEffect, useState } from "react";
import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useTeamContext } from "../../../contexts/TeamContext";
import Select, { SelectProps } from "../Select/Select";
import { Group } from "../../../types/types";

interface GroupSelectProps
{
	multiple?: boolean;
	currentlySelected?: number[];
	onChange: (group: Group[]) => void;
}

export default function GroupSelect({ onChange, multiple, currentlySelected, ...forward }: GroupSelectProps) {
	const client = useRestClientContext();
	const team = useTeamContext();

	const [groups, setGroups] = useState<Group[]>();
	const [error, setError] = useState<boolean>(false);
	const props: SelectProps = {
		...forward,
		clearable: true,
		multiple: multiple,
	};

	if (error) {
		props.loading = false;
		props.disabled = true;
		props.error = true;
		props.placeholder = "Couldn't fetch groups"
	} else if (!groups) {
		props.loading = true;
		props.disabled = true;
		props.placeholder = "Loading...";
		props.options = []; // NB: Avoid "selection" requires "options" warning from Semantic dropdown
	} else {
		props.options = groups.map((group: Group) => {
			return {
				key: group.id,
				text: group.name,
				value: group.id,
			};
		});
	}

	useEffect(() => {
		setError(false);
		client
			.fetchGroups(team.id)
			.then((response: Response) => response.json())
			.then((json: Group[]) => {
				setGroups(json);
			})
			.catch((error) => {
				console.error(error);
				setError(true);
			});
	}, [client, team.id]);

	// NB: Shim group object in here. Semantic complains if you pass it object values, so turn that back into a group object here
	return (
		<Select
			{...props}
			value={currentlySelected}
			onChange={(event, data) => {
				if (multiple) {
					const ids = data.value as Array<number>;
					onChange(groups!.filter((group) => ids.includes(group.id)));
				} else {
					const foundGroup = groups!.find((group) => group.id === data.value);
					if (foundGroup)
						onChange([foundGroup]);
				}
			}}
		/>
	);
}
