export default function ProgressVector() {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.99967 13.6666C6.08856 13.6666 5.22745 13.4916 4.41634 13.1416C3.60523 12.7916 2.8969 12.3138 2.29134 11.7083C1.68579 11.1027 1.20801 10.3944 0.858008 9.58325C0.508008 8.77214 0.333008 7.91103 0.333008 6.99992C0.333008 6.0777 0.508008 5.21381 0.858008 4.40825C1.20801 3.6027 1.68579 2.89714 2.29134 2.29159C2.8969 1.68603 3.60523 1.20825 4.41634 0.858252C5.22745 0.508252 6.08856 0.333252 6.99967 0.333252C7.18856 0.333252 7.3469 0.397141 7.47467 0.524919C7.60245 0.652696 7.66634 0.81103 7.66634 0.999919C7.66634 1.18881 7.60245 1.34714 7.47467 1.47492C7.3469 1.6027 7.18856 1.66659 6.99967 1.66659C5.5219 1.66659 4.26356 2.18603 3.22467 3.22492C2.18579 4.26381 1.66634 5.52214 1.66634 6.99992C1.66634 8.4777 2.18579 9.73603 3.22467 10.7749C4.26356 11.8138 5.5219 12.3333 6.99967 12.3333C8.47745 12.3333 9.73579 11.8138 10.7747 10.7749C11.8136 9.73603 12.333 8.4777 12.333 6.99992C12.333 6.81103 12.3969 6.6527 12.5247 6.52492C12.6525 6.39714 12.8108 6.33325 12.9997 6.33325C13.1886 6.33325 13.3469 6.39714 13.4747 6.52492C13.6025 6.6527 13.6663 6.81103 13.6663 6.99992C13.6663 7.91103 13.4913 8.77214 13.1413 9.58325C12.7913 10.3944 12.3136 11.1027 11.708 11.7083C11.1025 12.3138 10.3969 12.7916 9.59134 13.1416C8.78578 13.4916 7.9219 13.6666 6.99967 13.6666Z"
				fill="#1C4C61"
			/>
		</svg>
	);
}
