import { Tab } from "semantic-ui-react";

import { CustomImprovementsModalPanelProps } from "../../types/types";
import { useUserContext } from "../../contexts/UserContext";

import Modal from "../Modal/Modal";
import CustomPanel from './CustomPanel';
import HistoryPanel from "./HistoryPanel";
import SuggestedPanel from "./SuggestedPanel";
import SmartTargetsPanel from "./SmartTargets/SmartTargetsPanel";

import "./CustomImprovementSetsModal.scss";

export default function CustomImprovementSetsModal({ teamId, property, data, onComplete, onClose }: CustomImprovementsModalPanelProps) {

	const user = useUserContext();

	const panes = [
		{
			menuItem: "Custom",
			render: () => <Tab.Pane attached={false}>
				<CustomPanel
					teamId={teamId}
					property={property}
					data={data}
					onComplete={onComplete}
					onClose={onClose}
				/>
			</Tab.Pane>
		},
		/* {
			menuItem: "Presets",
			attached: false,
			render: () => <Tab.Pane>
				<PresetsPanel
					resource={resource}
					data={data}
					onComplete={onComplete}
					onClose={onClose}
				/>
			</Tab.Pane>
		}, */
		{
			menuItem: "History",
			render: () => <Tab.Pane attached={false}>
				<HistoryPanel
					teamId={teamId}
					property={property}
					data={data}
					onComplete={onComplete}
					onClose={onClose}
				/>
			</Tab.Pane>
		}
	];

	if(property)
	{
		panes.splice(1, 0, {
			menuItem: "Suggested",
			render: () => <Tab.Pane attached={false}>
				<SuggestedPanel
					property={property}
					data={data}
					onComplete={onComplete}
					onClose={onClose}
				/>
			</Tab.Pane>
		});

		if(user.is_admin)
			panes.push({
				menuItem: "Smart Targets",
				render: () => <Tab.Pane attached={false}>
					<SmartTargetsPanel
						property={property}
					/>
				</Tab.Pane>
			});
	}

	// TOOD: Can we break this up a bit into smaller components, if possible?
	return (
		<Modal
			title="Update Custom Measures"
			className="custom-improvement-sets-modal"
			onClose={() => onClose()}
			size="large"
		>
			<Tab panes={panes} menu={{ secondary: true }} />
		</Modal>
	);
}
