import { Tab } from "semantic-ui-react";

import ImportProperties from "../../components/Import/ImportProperties";
import ImportPropertyImages from "../../components/Import/ImportPropertyImages";

export default function ImportTabs({ importMonitorPath }: { importMonitorPath: string })
{
	return <Tab panes={[
		{
			menuItem: "Import Property Data",
			render: () => (
				<Tab.Pane>
					<ImportProperties importMonitorPath={importMonitorPath} />
				</Tab.Pane>
			),
		},
		{
			menuItem: "Import Property Images",
			render: () => (
				<Tab.Pane>
					<ImportPropertyImages />
				</Tab.Pane>
			),
		},
	]} />;
}