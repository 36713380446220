export default function AirHeatPumpVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1253"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1253)">
				<path
					d="M12 18C10.3333 18 8.91667 17.4167 7.75 16.25C6.58333 15.0833 6 13.6667 6 12C6 10.3333 6.58333 8.91667 7.75 7.75C8.91667 6.58333 10.3333 6 12 6C13.6667 6 15.0833 6.58333 16.25 7.75C17.4167 8.91667 18 10.3333 18 12C18 13.6667 17.4167 15.0833 16.25 16.25C15.0833 17.4167 13.6667 18 12 18ZM11.25 15.925V13.8L9.75 15.3C9.98333 15.45 10.2208 15.5792 10.4625 15.6875C10.7042 15.7958 10.9667 15.875 11.25 15.925ZM12.75 15.925C13.0167 15.875 13.275 15.7958 13.525 15.6875C13.775 15.5792 14.0167 15.45 14.25 15.3L12.75 13.8V15.925ZM15.3 14.25C15.45 14.0167 15.5792 13.775 15.6875 13.525C15.7958 13.275 15.875 13.0167 15.925 12.75H13.8L15.3 14.25ZM13.8 11.25H15.925C15.875 10.9833 15.7958 10.725 15.6875 10.475C15.5792 10.225 15.45 9.98333 15.3 9.75L13.8 11.25ZM12.75 10.2L14.25 8.7C14.0167 8.55 13.7792 8.42083 13.5375 8.3125C13.2958 8.20417 13.0333 8.125 12.75 8.075V10.2ZM12 13C12.2833 13 12.5208 12.9042 12.7125 12.7125C12.9042 12.5208 13 12.2833 13 12C13 11.7167 12.9042 11.4792 12.7125 11.2875C12.5208 11.0958 12.2833 11 12 11C11.7167 11 11.4792 11.0958 11.2875 11.2875C11.0958 11.4792 11 11.7167 11 12C11 12.2833 11.0958 12.5208 11.2875 12.7125C11.4792 12.9042 11.7167 13 12 13ZM11.25 10.2V8.075C10.9833 8.125 10.725 8.20417 10.475 8.3125C10.225 8.42083 9.98333 8.55 9.75 8.7L11.25 10.2ZM8.075 11.25H10.2L8.7 9.75C8.55 9.98333 8.42083 10.225 8.3125 10.475C8.20417 10.725 8.125 10.9833 8.075 11.25ZM8.7 14.25L10.2 12.75H8.075C8.125 13.0167 8.20417 13.275 8.3125 13.525C8.42083 13.775 8.55 14.0167 8.7 14.25ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
