export default function BoilerFlameVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1258"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1258)">
				<path
					d="M6 14C6 14.8667 6.175 15.6875 6.525 16.4625C6.875 17.2375 7.375 17.9167 8.025 18.5C8.00833 18.4167 8 18.3417 8 18.275V18.05C8 17.5167 8.1 17.0167 8.3 16.55C8.5 16.0833 8.79167 15.6583 9.175 15.275L12 12.5L14.825 15.275C15.2083 15.6583 15.5 16.0833 15.7 16.55C15.9 17.0167 16 17.5167 16 18.05V18.275C16 18.3417 15.9917 18.4167 15.975 18.5C16.625 17.9167 17.125 17.2375 17.475 16.4625C17.825 15.6875 18 14.8667 18 14C18 13.1667 17.8458 12.3792 17.5375 11.6375C17.2292 10.8958 16.7833 10.2333 16.2 9.65C15.8667 9.86667 15.5167 10.0292 15.15 10.1375C14.7833 10.2458 14.4083 10.3 14.025 10.3C12.9917 10.3 12.0958 9.95833 11.3375 9.275C10.5792 8.59167 10.1417 7.75 10.025 6.75C9.375 7.3 8.8 7.87083 8.3 8.4625C7.8 9.05417 7.37917 9.65417 7.0375 10.2625C6.69583 10.8708 6.4375 11.4917 6.2625 12.125C6.0875 12.7583 6 13.3833 6 14ZM12 15.3L10.575 16.7C10.3917 16.8833 10.25 17.0917 10.15 17.325C10.05 17.5583 10 17.8 10 18.05C10 18.5833 10.1958 19.0417 10.5875 19.425C10.9792 19.8083 11.45 20 12 20C12.55 20 13.0208 19.8083 13.4125 19.425C13.8042 19.0417 14 18.5833 14 18.05C14 17.7833 13.95 17.5375 13.85 17.3125C13.75 17.0875 13.6083 16.8833 13.425 16.7L12 15.3ZM12 3V6.3C12 6.86667 12.1958 7.34167 12.5875 7.725C12.9792 8.10833 13.4583 8.3 14.025 8.3C14.325 8.3 14.6042 8.2375 14.8625 8.1125C15.1208 7.9875 15.35 7.8 15.55 7.55L16 7C17.2333 7.7 18.2083 8.675 18.925 9.925C19.6417 11.175 20 12.5333 20 14C20 16.2333 19.225 18.125 17.675 19.675C16.125 21.225 14.2333 22 12 22C9.76667 22 7.875 21.225 6.325 19.675C4.775 18.125 4 16.2333 4 14C4 11.85 4.72083 9.80833 6.1625 7.875C7.60417 5.94167 9.55 4.31667 12 3Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
