export default function UnderfloorInsulationVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1376"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1376)">
				<path d="M7 9L15.4853 13.8986" stroke="#1C1B1F" strokeWidth="1.5" />
				<path d="M9 7L17.4853 11.8986" stroke="#1C1B1F" strokeWidth="1.5" />
				<circle cx="17.75" cy="12.75" r="0.75" fill="#1C1B1F" />
				<circle cx="16.75" cy="15.75" r="0.75" fill="#1C1B1F" />
				<circle cx="13.75" cy="15.25" r="0.75" fill="#1C1B1F" />
				<circle cx="12.25" cy="18.25" r="0.75" fill="#1C1B1F" />
				<circle cx="10.25" cy="15.25" r="0.75" fill="#1C1B1F" />
				<circle cx="7.25" cy="15.75" r="0.75" fill="#1C1B1F" />
				<circle cx="5.75" cy="12.75" r="0.75" fill="#1C1B1F" />
				<path
					d="M4.32007 10.7076C3.87525 10.4508 3.87525 10.0345 4.32007 9.77771L11.9999 5.34412L19.6798 9.77771C20.1246 10.0345 20.1246 10.4508 19.6798 10.7076L11.9999 15.1412L4.32007 10.7076Z"
					stroke="#1C1B1F"
					strokeWidth="2"
				/>
				<path
					d="M20.0147 10.102L19.9991 15L11.9991 19.5L4.00112 15L4.00045 10.0514"
					stroke="#1C1B1F"
					strokeWidth="2"
				/>
			</g>
		</svg>
	);
}
