export default function GroundHeatPumpVector() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2315_1295"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2315_1295)">
				<path
					d="M7 18.6C6.16667 18.6 5.45833 18.3083 4.875 17.725C4.29167 17.1417 4 16.4333 4 15.6V6.6H6V15.6C6 15.8833 6.09583 16.1208 6.2875 16.3125C6.47917 16.5042 6.71667 16.6 7 16.6C7.28333 16.6 7.52083 16.5042 7.7125 16.3125C7.90417 16.1208 8 15.8833 8 15.6V9.6C8 8.76667 8.29167 8.05833 8.875 7.475C9.45833 6.89167 10.1667 6.6 11 6.6C11.8333 6.6 12.5417 6.89167 13.125 7.475C13.7083 8.05833 14 8.76667 14 9.6V15.6C14 15.8833 14.0958 16.1208 14.2875 16.3125C14.4792 16.5042 14.7167 16.6 15 16.6C15.2833 16.6 15.5208 16.5042 15.7125 16.3125C15.9042 16.1208 16 15.8833 16 15.6V8.6C16 7.76667 16.2917 7.05833 16.875 6.475C17.4583 5.89167 18.1667 5.6 19 5.6H20.175L19 4.425L20.4 3L24 6.6L20.4 10.175L19 8.75L20.175 7.6H19C18.7167 7.6 18.4792 7.69583 18.2875 7.8875C18.0958 8.07917 18 8.31667 18 8.6V15.6C18 16.4333 17.7083 17.1417 17.125 17.725C16.5417 18.3083 15.8333 18.6 15 18.6C14.1667 18.6 13.4583 18.3083 12.875 17.725C12.2917 17.1417 12 16.4333 12 15.6V9.6C12 9.31667 11.9042 9.07917 11.7125 8.8875C11.5208 8.69583 11.2833 8.6 11 8.6C10.7167 8.6 10.4792 8.69583 10.2875 8.8875C10.0958 9.07917 10 9.31667 10 9.6V15.6C10 16.4333 9.70833 17.1417 9.125 17.725C8.54167 18.3083 7.83333 18.6 7 18.6ZM3 21.6C2.45 21.6 1.97917 21.4042 1.5875 21.0125C1.19583 20.6208 1 20.15 1 19.6V11.6H23V19.6C23 20.15 22.8042 20.6208 22.4125 21.0125C22.0208 21.4042 21.55 21.6 21 21.6H3ZM3 19.6H21V13.6H3V19.6Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
}
