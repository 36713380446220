import React, { PropsWithChildren, ReactElement } from "react";
import { Header } from "semantic-ui-react";

import "./CardItem.scss";

interface CardItemProps extends PropsWithChildren {
	title?: string;
	icon?: ReactElement
	className?: string;
}

export function CardItem({ title, icon, children, className }: CardItemProps) {
	return (
		<div className={`card-item ${className ?? ""}`}>
			<div className="item-header-container">
				{icon}
				{title && <Header as={"h4"}>{title}</Header>}
			</div>
			<div className="card-item-content">
				{children}
			</div>
		</div>
	);
}
