export default function ErrorVector() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_2106_1570"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="16"
				height="16"
			>
				<rect width="16" height="16" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2106_1570)">
				<path
					d="M2 13.3334V12.0001H3.83333L3.56667 11.7667C2.98889 11.2556 2.58333 10.6723 2.35 10.0167C2.11667 9.36119 2 8.70008 2 8.03341C2 6.80008 2.36944 5.70286 3.10833 4.74175C3.84722 3.78064 4.81111 3.14453 6 2.83341V4.23341C5.2 4.5223 4.55556 5.01397 4.06667 5.70841C3.57778 6.40286 3.33333 7.17786 3.33333 8.03341C3.33333 8.53341 3.42778 9.01953 3.61667 9.49175C3.80556 9.96397 4.1 10.4001 4.5 10.8001L4.66667 10.9667V9.33342H6V13.3334H2ZM8 11.3334C7.81111 11.3334 7.65278 11.2695 7.525 11.1417C7.39722 11.014 7.33333 10.8556 7.33333 10.6667C7.33333 10.4779 7.39722 10.3195 7.525 10.1917C7.65278 10.064 7.81111 10.0001 8 10.0001C8.18889 10.0001 8.34722 10.064 8.475 10.1917C8.60278 10.3195 8.66667 10.4779 8.66667 10.6667C8.66667 10.8556 8.60278 11.014 8.475 11.1417C8.34722 11.2695 8.18889 11.3334 8 11.3334ZM7.33333 8.66675V4.66675H8.66667V8.66675H7.33333ZM10 13.1667V11.7667C10.8 11.4779 11.4444 10.9862 11.9333 10.2917C12.4222 9.5973 12.6667 8.8223 12.6667 7.96675C12.6667 7.46675 12.5722 6.98064 12.3833 6.50841C12.1944 6.03619 11.9 5.60008 11.5 5.20008L11.3333 5.03341V6.66675H10V2.66675H14V4.00008H12.1667L12.4333 4.23341C12.9778 4.77786 13.375 5.36953 13.625 6.00841C13.875 6.6473 14 7.30008 14 7.96675C14 9.20008 13.6306 10.2973 12.8917 11.2584C12.1528 12.2195 11.1889 12.8556 10 13.1667Z"
					fill="#BF2E2E"
				/>
			</g>
		</svg>
	);
}
